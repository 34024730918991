import React, { useEffect, useState,useRef } from "react";
import BGIMG from "../../assets/Images/bg-image.png";
import EVChargerImg from "../../assets/Images/ev-charger1.png";
import CarImg from "../../assets/Images/car-img1.png";
import CarCharging from "../../assets/Images/charging-point-1.jpg";
import Circle from "../../assets/Images/circle.png";
import BatteryImg from "../../assets/Images/battery-img.png";
import Service1 from "../../assets/Images/s1.jpg";
import Service2 from "../../assets/Images/s2.jpg";
import Service3 from "../../assets/Images/s3.jpg";
import Service4 from "../../assets/Images/s4.jpg";
import E1 from "../../assets/Images/e1.jpg";
import E2 from "../../assets/Images/e2.jpg";
import E3 from "../../assets/Images/e3.jpg";
import BG2 from "../../assets/Images/bg-2.png";
import slider1 from "../../assets/Images/slider1.jpg";
import slider2 from "../../assets/Images/slider2.jpg";
import BGPlugIMG from "../../assets/Images/ev-plug-image2-1.png";
import BGMapIMG from "../../assets/Images/map2.png";
import Mobile from "../../assets/Images/14.png";
import PlayStore from "../../assets/Images/googleplay-light-1.png";
import AppStore from "../../assets/Images/appstore-light-1.png";
import BG3 from "../../assets/Images/lines-waves1-1.png";
import LineImg from "../../assets/Images/wave-img-1.png";
import ProcessPlug from "../../assets/Images/process-plug.png";
import BG4 from "../../assets/Images/director-img21-1.png";
import ChargerImg from "../../assets/Images/charger-img1-1.png";
import {
  ChatBubbleOvalLeftIcon,
  ShareIcon,
  Cog6ToothIcon,
  CogIcon,
  BoltIcon,
  ChevronRightIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/solid";
import "./style.css"; 
import { Rating } from "@mui/material";
import {Carousel } from "@material-tailwind/react";
import { easeInOut, motion, useScroll, useTransform } from "framer-motion";
import { Link } from "react-router-dom";
import CustomCarousel from "./Component/Slider";
import Slider from "react-slick";



const Home = () => {
  const [chargingCount, setChargingCount] = useState(0);

  // useEffect(() => {
  //   const duration = 5000;
  //   const maxCount = 100;
  //   // const interval = duration /  maxCount;

  //   const intervalID = setInterval(() => {
  //     setChargingCount((prevcount) =>
  //       prevcount < maxCount ? prevcount + 1 : 0
  //     );
  //   }, 50);

  //   return clearInterval(intervalID);
  // }, []);

  useEffect(() => {
    let counterIntervall = setInterval(() => {
      setChargingCount(prevCount => {
        if (prevCount >= 100) {
          return 0;
        }
        return prevCount + 1;
      });
    }, 50); // Update every 50 milliseconds (0.05 seconds)

    return () => clearInterval(counterIntervall);
  }, []);

  
  // const NextArrow =(props)=>{
  //   const {className,style,onClick}=props;
  //   return(
  //     <div onClick={onClick} className={`arrow ${className} z-20`} >
  //         <ArrowRightIcon class="arrows" style={{color:"white"}}/>
  //       </div>
  //   )
  // }
  // const PrevArrow =(props)=>{
  //   const {className,style,onClick}=props;
  //   return(
  //     <div onClick={onClick} className={`arrow ${className} z-20`} >
  //         <ArrowLeftIcon class="arrows" style={{color:"white"}}/>
  //       </div>
  //   )
  // }
  const slider = React.useRef(null);
  function SampleNextArrow(props) {
    // console.log("trigg")
    const { className, style, onClick } = props;
    return (
      <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
    );
  }
  function SamplePrevArrow(props) {
    // console.log("triggr")
    const { onClick } = props;
    return (
      <div onClick={onClick} className="left-container bg-primary rounded-full p-2">
       <ArrowLeftIcon className="arrows h-5 w-5 " style={{color:"white"}}/>
      </div>
    );
  }
  const settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "60px",
    autoplay:true,
    slidesToShow: 3,
    speed: 500,
    rows: 1,
    slidesPerRow: 1,
    // nextArrow:<SampleNextArrow/>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows:true,
          autoplay:true,
 
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          arrows:false,
          infinite:true,
          autoplay:true,
          arrows:false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite:true,
          autoplay:true,
          arrows:false,
        },
      },
    ],
  };

  // var section5Slider = {
  //   dots: true,
  //   fade: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   waitForAnimate: false
  // };
  const sliderData = [
    {
      id: 1,
      img: Service3, // Randomly shuffled image
      title: "Wide Coverage",
      description: "Access a vast network of charging stations across urban and rural areas, ensuring you're never far from a power source."
    },
    {
      id: 2,
      img: Service1, // Randomly shuffled image
      title: "Location-Based Search",
      description: "Find charging stations based on your current location, destination, or specific routes."
    },
    {
      id: 3,
      img: Service4, // Randomly shuffled image
      title: "Diverse Options",
      description: "Choose from a variety of charging types, including fast chargers, standard chargers, and eco-friendly options."
    },
    {
      id: 4,
      img: Service2, // Randomly shuffled image
      title: "High-Quality Chargers",
      description: "Purchase reliable and efficient chargers directly through our app."
    },
    {
      id: 5,
      img: Service1, // Randomly shuffled image
      title: "Wide Selection",
      description: "Choose from a variety of chargers suitable for different devices and needs."
    },
    {
      id: 6,
      img: Service3, // Randomly shuffled image
      title: "Competitive Pricing",
      description: "Enjoy affordable prices and exclusive discounts for app users."
    },
    {
      id: 7,
      img: Service4, // Randomly shuffled image
      title: "Easy Ordering",
      description: "Conveniently browse and order chargers with quick delivery options."
    },
    {
      id: 8,
      img: Service2, // Randomly shuffled image
      title: "Insurance",
      description: "Get comprehensive insurance coverage for your vehicle with our trusted partners."
    },
    {
      id: 9,
      img: Service1, // Randomly shuffled image
      title: "Car Assistance",
      description: "Access a range of car assistance services, including maintenance and repairs, to keep your vehicle in top condition."
    },
    {
      id: 10,
      img: Service3, // Randomly shuffled image
      title: "Car Accessories",
      description: "Browse and purchase high-quality car accessories to enhance your driving experience."
    },
    {
      id: 11,
      img: Service4, // Randomly shuffled image
      title: "Roadside Assistance",
      description: "Enjoy peace of mind with our reliable roadside assistance, available 24/7 for any emergencies."
    }
  ];
  

  const features = [
    {
      id: 1,
      title: "High Quality",
      description: "We ensure top-notch service and reliable, safe charging solutions for your electric vehicle."
    },
    {
      id: 2,
      title: "Fast Charging",
      description: "Lorem ipsum dolor sit amet, elit onsect ligula eget dolor tend to predefined.."
    },
    {
      id: 3,
      title: "24/7 Support",
      description: "Our team is available anytime to help with questions or issues, ensuring a smooth experience."
    },
    {
      id: 4,
      title: "Wall-mounted",
      description: "Lorem ipsum dolor sit amet, elit onsect ligula eget dolor tend to predefined.."
    }
  ]



  
  const [hoverIndex, setHoverIndex] = useState(null);
  return (
    <div>
      <section
        className="min-h-[90vh]  w-full relative"
        style={{ background: `url(${BGIMG}) no-repeat center center` }}

      >

        <div className="grid grid-cols-1 xl:grid-cols-[1.3fr,1fr] lg:grid-cols-[1.5fr,1fr] md:grid-cols-[1.5fr,1fr] min-h-[90vh] overflow-hidden">

          
          <div className="w-full  flex flex-col xl:items-start lg:items-start md:items-start items-center justify-center xl:px-10 lg:px-10 md:px-10 sm:px-10 gap-4 relative h-[350px] xl:h-[650px] lg:h-[650px] md:h-[450px] ">
          <motion.div
      initial={{ opacity: 0, scale: 1, x: '-50%', y: '100%', width: '0%' }}
      animate={{ opacity: 0.2, scale: 1.5, x: '0%', y: '0%', width: '100%' }}
      transition={{
        duration: 5,
        ease: 'linear',
        repeat: 0,
      }}
      className="absolute inset-0 h-full z-6 rounded-tr-full hidden xl:block lg:block "
      style={{
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'radial-gradient(at center center, #ffffff0d 0%, #84c951 100%)',
      }}
    />
         
            < motion.h4 initial={{opacity:0, y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.5,ease:'easeInOut'}} className="font-bold text-primary lg:text-xl md:text-xs z-10">
              SMART EV CHARGING STATIONS
            </motion.h4>
            <motion.h2 initial={{opacity:0, }} whileInView={{opacity:1,}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}} className="font-bold xl:text-[80px] lg:text-6xl md:text-4xl text-2xl xl:text-start lg:text-start md:text-start text-center xl:leading-[1em] z-10">
            Charge Anywhere, Anytime with India1 Charger
            </motion.h2>
            <Link to='/download-app'>
            <button className="bg-primary px-5 py-3 font-semibold font-DM-Sans text-white relative rounded-lg z-10 h-[50px] group"> <span className="absolute inset-0 w-0 h-full bg-white transition-all duration-300 ease-in-out group-hover:w-full group-hover:bg-white/20"></span>Download the App Now</button></Link>
          </div>
          <div className="grid xl:grid-cols-[1.2fr,1fr] grid-cols-2  ">
            <div className="h-full w-full lg:flex-col md:flex-col flex-row flex items-center justify-between relative">
              <div className="xl:h-[250px] lg:h-[250px] md:h-[200px] h-[150px] my-5 xl:absolute lg:absolute md:absolute relative xl:top-[20%] lg:top-[20%] md:top-[30%] top-0">
                <img
                  src={EVChargerImg}
                  alt="Evcharger.png"
                  className="h-full w-full object-contain animate-pulse"
                />
              </div>
              <div className="xl:h-[300px] xl:w-[500px] lg:h-[280px] lg:w-[400px] md:h-[200px] md:w-[300px] h-[120px] w-[200px] absolute bottom-[0%] -right-[0%]">
                <img
                  src={CarImg}
                  alt="EvCar.png"
                  className="h-full w-full lg:object-fit md:object-fit object-contain "
                />
              </div>
            </div>
            <div className=" h-full w-full relative overflow-hidden">
              <div className="xl:h-[300px]  lg:h-[250px] md:h-[200px] xl:w-[200px] lg:w-[150px] md:w-[150px] h-[110px] w-[70px] bg-primary  absolute bottom-0 z-10 border border-primary rounded-lg ">
                <div className="h-[10px] w-[30px] absolute -top-2 bg-primary left-1/2 transform -translate-x-1/2 "></div>
                <div className="h-[40px] w-[100px] absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <img
                    src={BatteryImg}
                    alt="Battery.png"
                    className="h-full w-full object-contain animate-pulse"
                  />
                </div>

                <div className="h-full bg-[#ededed] animate-progress rounded-t-lg"></div>
              </div>

              <div className="h-[full] absolute bottom-0 -right-22   ">
                <div className="absolute transform lg:-translate-x-1/4 xl:left-[40%] lg:left-[25%] md:left-[15%] xl:-top-[5%] lg:-top-[2%]  z-10 bg-primary h-[70px] w-[70px] flex flex-col items-center justify-center rounded-full ">
                  <span className="animate-pulse font-bold text-[white]">
                    {chargingCount}%
                  </span>
                </div>
                <div className="h-[200px] xl:h-[500px] lg:h-[500px] md:h-[500px] w-[200px] xl:w-[500px] lg:w-[500px] md:w-[500px]">
                <img
                  src={Circle}
                  alt="EvCar.png" 
                  className="h-full w-full object-cover animate-spin360 opacity-30"
                />

                </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="min-h-screen relative flex flex-col justify-center items-center my-10 "
        style={{
          background: `url(${CarCharging}) no-repeat`,
          backgroundPosition: "center",
          backgroundSize: "600px 600px",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="grid xl:grid-cols-2 md:grid-cols-1 gap-10 xl:w-[90%] sm:w-full z-10 overflow-hidden">
          <div className="bg-[#f6f8f9] p-5 flex flex-col gap-10 items-center justify-around  xl:w-3/4 h-[600px] lg:w-full overflow-hidden">
            <motion.div className="flex gap-5 group" initial={{opacity:0,x:-100}} whileInView={{opacity:1,x:0}} viewport={{once:false}} transition={{duration:0.5,ease:'easeInOut'}}>
              <div>
                <CogIcon
                  fontSize={12}
                  className="w-12 stroke-primary fill-primary group-hover:stroke-[black] group-hover:fill-[black]"
                />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="font-bold text-[18px] font-Jakarta">
                Find Charging Stations Easily
                </h4>
                <p className="text-[16px] font-DM-Sans">
                Locate the nearest charging points with our user-friendly map interface. Filter by location, availability, and type to find the perfect match for your device.
                </p>
              </div>
            </motion.div>
            <motion.div className="flex gap-5 group" initial={{opacity:0,x:-100}} whileInView={{opacity:1,x:0}} viewport={{once:false}} transition={{duration:0.5,ease:'easeInOut'}}>
              <div>
                <Cog6ToothIcon
                  fontSize={12}
                  className="w-12 stroke-primary fill-primary group-hover:stroke-[black] group-hover:fill-[black]"
                />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="font-bold font-Jakarta text-[20px]">
                Save Time and Money
                </h4>
                <p className="font-DM-Sans text-[16px]">
                Avoid the frustration of searching for a charger. Our app saves you time and helps you find the best deals.
                </p>
              </div>
            </motion.div>
            <motion.div className="flex gap-5 group" initial={{opacity:0,x:-100}} whileInView={{opacity:1,x:0}} viewport={{once:false}} transition={{duration:0.5,ease:'easeInOut'}}>
              <div>
                <BoltIcon
                  fontSize={12}
                  className="w-12 stroke-primary fill-primary group-hover:stroke-[black] group-hover:fill-[black]"
                />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="font-bold font-Jakarta text-[20px]">
                Convenience at Your Fingertips
                </h4>
                <p className="font-DM-Sans text-[16px]">
                Access charging stations across the country with just a few taps. Never worry about running out of battery again.
                </p>
              </div>
            </motion.div>
          </div>
          <motion.div initial={{opacity:0, x:100}} whileInView={{opacity:1,x:0}} viewport={{once:false}} transition={{duration:0.5,ease:'linear'}} className="bg-[white] xl:h-1/2 md:h-full flex flex-col items-start justify-center  xl:w-3/4 shadow-lg rounded-lg px-4 gap-5 overflow-hidden">
            <div className="flex flex-row items-center gap-5">
              <div className="w-8 h-2 border-primary border rounded-full ">
                <div className="w-full h-full bg-primary animate-progressHorizontal"></div>
              </div>
              <span className="text-[15px] font-DM-Sans uppercase font-semibold">
                About Us
              </span>
              <BoltIcon className="h-5 fill-primary" />
            </div>
            <h3 className=" text-xl font-bold font-Jakarta">
            Stay connected, stay powered, and enjoy seamless access to charging solutions across India.
            </h3>
           <Link to="/about-us"> <button  className="bg-primary p-2 text-[white] font-semibold font-DM-Sans hover:bg-black rounded-lg">
              Learn More
            </button></Link>
          </motion.div>
        </div>
      </section>
      <section className="flex flex-col gap-5 items-center justify-center">
        <div className="flex flex-row gap-4 items-center">
          <div className="w-8 h-2 border-primary border rounded-full ">
            <div className="w-full h-full bg-primary animate-progressHorizontal"></div>
          </div>
          <motion.span initial={{opacity:0}} whileInView={{opacity:1}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}} className="text-[15px] font-DM-Sans uppercase font-semibold">
            Our Services
          </motion.span>

          <BoltIcon className="h-5 fill-primary" />
        </div>
        <motion.h2 initial={{opacity:0, y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.5,ease:'easeInOut'}} className="lg:text-4xl md:text-2xl text-xl text-center leading-snug font-bold font-Jakarta">
          We Are Best Service Provider <br /> Electric Vehicle
        </motion.h2>
        <div className="w-3/4 ">
          <Slider ref={slider} {...settings} >
            {sliderData.map((item, index) => (
              <>
                <div className="bg-[white] flex flex-col items-start p-5 justify-center gap-5 shadow-lg rounded-lg group h-[400px] m-2">
                  <div className="relative h-[200px] w-full" >
                    <img
                      src={item.img}
                      alt=""
                      className="self-stretch flex-shrink-0 object-cover w-full transition transform duration-[0.5s] ease-in-out group  group-hover:scale-110"
                      style={{height:"200px",objectFit:"cover"}}
                    />
                  </div>

                  <div className="h-[150px] flex flex-col gap-5">
                  <h5 className="font-Jakarta font-bold text-xl">{item.title}</h5>

<span className="font-DM-Sans text-[16px]  text-ellipsis text-wrap overflow-hidden  line-clamp-3 group-hover:text-primary">
  {item.description}
</span>

                  </div>


                </div>
              </>
            ))}
          </Slider>
        </div>
        <div className=" my-5 min-h-screen w-full bg-[#f0f4f6] flex flex-col items-center justify-center  "  style={{
              background: `url(${BGPlugIMG}) no-repeat`,
              backgroundPosition: "left",
              backgroundSize: "contain",
            }}>
          <div
            // style={{
            //   background: `url(${BGPlugIMG}) no-repeat`,
            //   backgroundPosition: "left",
            //   backgroundSize: "contain",
            // }}
            className="h-full  w-full flex flex-col items-center justify-center "
          >

            <div className="grid xl:grid-cols-[2fr,1fr] lg:grid-cols-[2fr,1fr] md:grid-cols-1 lg:w-3/4 xl:w-2/3 md:w-4/5 w-11/12 bg-primary ">
          <div className="flex flex-col  gap-8 lg:px-10 md:px-10 justify-center">
            <motion.h2 initial={{opacity:0, y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.5,ease:'easeInOut'}} className="font-Jakarta text-[65px] leading-tight font-extrabold text-[#fff]">
            Need an <br/> <span className="stroke-1 text-stroke stroke-[white] text-[transparent] selection:text-[white]" style={{WebkitTextStrokeColor:'white'}}>Electric</span> Charger ?
            </motion.h2>
            <p className="text-[16px] font-DM-Sans text-[white] text-justify">
            We're here to help! Whether you have questions, need support, or want to give feedback, our team is ready to assist. Let India1 Charger power your journey!
            </p>
            <Link to="/contact">
            <motion.button  initial={{opacity:0}} whileInView={{opacity:1}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}} className="relative block bg-black text-white px-5 py-2 rounded-md w-full md:w-1/2 lg:w-1/2 xl:w-1/2 font-semibold overflow-hidden group  ">
  Contact us
  <span className="absolute inset-0 w-0 h-full bg-white transition-all duration-300 ease-in-out group-hover:w-full group-hover:bg-[#ffffff3d]"></span>

</motion.button>
</Link>


          </div>
          <div className="flex flex-col gap-5 ">
            <div className="h-[250px] py-5 px-2 hidden  md:hidden  xl:flex lg:flex">
              <img src={E2} alt="electric.jpg" className="h-full w-full xl:object-contain md:object-cover" />

            </div>
            <div className="h-[250px] py-2 px-2 bg-[#f1f4f6] border-b border-primary border-r ">
              <img src={E1} alt="electric.jpg" className="h-full w-full object-cover" />

            </div>
          </div>

          </div>
          </div>
          
        </div>
      </section>
      <section className="h-auto  bg-[#f1f4f6] grid lg:grid-cols-[1fr,1fr,1fr] xl:[1fr,2fr,2fr] gap-5 md:grid-cols-2 relative ">
        <div className="h-full  flex flex-col items-center justify-center w-full ">
          <div className="transform lg:-rotate-[90deg] md:-rotate-[90deg] rotate-0  w-max">
            <h2 className="lg:text-6xl md:text-5xl text-3xl font-bold text-stroke text-[transparent] font-Jakarta stroke-primary selection:text-primary" style={{WebkitTextStrokeColor:'#93c762'}}>Our Projects</h2>
          </div>

        </div>
        <div className="h-full  flex flex-col justify-center gap-3">
        <div className="flex flex-row gap-5 items-center">
          <div className="w-8 h-2 border-primary border rounded-full ">
            <div className="w-full h-full bg-primary animate-progressHorizontal"></div>
          </div>
          <motion.span initial={{opacity:0}} whileInView={{opacity:1}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}} className="text-[15px] font-DM-Sans uppercase font-semibold">
            Our Services
          </motion.span>

          <BoltIcon className="h-5 fill-primary" />
        </div>
        <motion.h2 initial={{opacity:0, y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.5,ease:'easeInOut'}} className="lg:text-4xl md:text-2xl text-xl  font-bold font-Jakarta ">
        Smart and Sustainable Electric Car Charging.
        </motion.h2>
        <p className="text-[16px] text-[#797979] font-DM-Sans">
        We offer cutting-edge electric car charging solutions that are both smart and sustainable. Our services are designed to make EV charging easy, efficient, and environmentally friendly. Trust India1 Charger for reliable and advanced charging technology.

        </p>
        <div className="flex gap-4 items-center xl:flex-row lg:flex-row md:flex-row flex-col">
          <div className="h-[200px] w-[210px]">
            <img src={E3} alt="ev.png" className="h-full w-full object-fill" />
          </div>
          <div className="h-[200px] w-[210px] relative shadow-md rounded-lg flex flex-col  justify-center">
  <div
    className="h-full w-full absolute"
    style={{
      background: `url(${BG2}) no-repeat center center`,
      backgroundSize: "cover",
      opacity: 0.13,
      zIndex: 1,
    }}
  ></div>
  <div className="z-10 flex flex-col items-center gap-2">

  
  <div >
  <h5 className="text-[22px] font-Jakarta font-bold text-[black] relative ">
    Excellent
  </h5>
  <Rating name="half-rating" readOnly defaultValue={4.5} precision={0.5}  color="#fff" size="42" sx={{fill:'red', color:'#93c762',fontSize:18}}  />

  </div>

  <p className="text-[12px] text-center font-DM-Sans">
  Trust Score 4.7 (Based on 1,200 reviews)
  </p>
  <a className="text-center font-bold  flex items-center hover:text-primary font-DM-Sans cursor-pointer ">
    Read More  <ChevronRightIcon className="h-5 w-5 font-bold"/>
  </a>
  </div>
 
</div>

        </div>

        </div>
      
        <div className="h-full w-full md:col-span-2 lg:col-auto " >
              <Carousel className="" nextArrow={false} prevArrow={false} autoplay  loop navigation={({setActiveIndex,activeIndex,length})=>(
                <div className="absolute bottom-10 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                  {
                    new Array(length).fill("").map((_,i)=>(
                      <span key={i} className={`block h-1 cursor-pointer rounded-2xl transition-all content-['] ${activeIndex === i ? 'w-8 bg-primary':'w-4 bg-primary/50'}`} onClick={()=>setActiveIndex(i)}>

                      </span>
                    ))
                  }
                </div>
              )} >
              <div className="relative group">
  <div className="h-full w-full overflow-hidden">
    <img
      src={slider1}
      alt=""
      className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-110"
    />
  </div>
  <div className="h-full w-full absolute inset-0 group-hover:backdrop-blur-sm group-hover:z-10 transition-all duration-300"></div>
  <div className="absolute z-20 bottom-0 left-0 group-hover:left-20 group-hover:bottom-20  flex-col transform hidden group-hover:flex transition-all duration-200 group-hover:animate-fadein">
    <h4 className="font-bold text-white text-3xl mb-2 font-Jakarta ">Road Trip with Ev</h4>
    <p className="text-xs font-DM-Sans text-white font-semibold">Charge, Corporate</p>
  </div>
</div>
              <div className="relative group">
  <div className="h-full w-full overflow-hidden">
    <img
      src={slider2}
      alt=""
      className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-110"
    />
  </div>
  <div className="h-full w-full absolute inset-0 group-hover:backdrop-blur-md group-hover:z-10 transition-all duration-300"></div>
  <div className="absolute z-20 bottom-0 left-0 group-hover:left-20 group-hover:bottom-20  flex-col transform hidden group-hover:flex transition-all duration-200 group-hover:animate-fadein">
    <h4 className="font-bold text-white text-3xl mb-2 font-Jakarta ">Charging Stations</h4>
    <p className="text-xs font-DM-Sans text-white font-semibold">Charge, Corporate</p>
  </div>
</div>



              </Carousel>
               
       

        </div>
      </section>
      <section className="h-fit flex flex-col items-center justify-center py-5" 
        style={{
          background: `url(${BGMapIMG}) no-repeat`,
          backgroundPosition: "left center",
          backgroundSize: "400px 300px",
        }} 
      >
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 w-3/4">
          <div>
            <motion.div initial={{opacity:0,x:-200}} whileInView={{opacity:1,x:0}} viewport={{once:false}} transition={{duration:0.8,ease:'linear'}} className="h-[500px]">
              <img src={Mobile} alt="" className="h-full w-full object-contain" />
            </motion.div>
          </div>
          <div className=" flex flex-col items-center justify-center gap-5">
          <div className="flex flex-row gap-5 items-center w-full">
          <div className="w-8 h-2 border-primary border rounded-full ">
            <div className="w-full h-full bg-primary animate-progressHorizontal"></div>
          </div>
          <motion.span initial={{opacity:0}} whileInView={{opacity:1}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}} className="text-[15px] font-DM-Sans uppercase font-semibold">
          SERVICE POINTS
          </motion.span>

          <BoltIcon className="h-5 fill-primary" />
        </div>
        <motion.h2 initial={{opacity:0, y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.5,ease:'easeInOut'}} className="lg:text-4xl md:text-2xl text-xl  font-bold font-Jakarta">
        Great App That Makes <span className="text-primary">Your Life</span>Too Easier.
        </motion.h2>
      
       
        <p className="text-[16px] text-[#797979] font-DM-Sans">Experience the convenience and reliability of India1 Charger. Download our app now and join the community of users who trust us to keep their devices powered up and ready for anything.</p>

            <div className="gap-5 flex w-full">
              <button className="bg-primary px-5 py-2 h-[40px] hover:bg-black rounded-lg"><img src={PlayStore} alt="googleplay.png" className="h-full w-full object-contain" /></button>
              <button className="bg-black px-5 py-2 h-[40px] hover:bg-primary rounded-lg"><img src={AppStore} alt="appstore.png" className="h-full w-full object-contain" /></button>
            </div>
          </div>
        </div>
      </section>
      <section className="min-h-screen bg-[#1a1919] ">
        <div className=" flex flex-col items-center justify-center py-20"  style={{
              background: `url(${BG3}) no-repeat`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              opacity:1

            }} >
              <div className="w-3/4 grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 place-items-center "  style={{
              background: ` url(${LineImg}) no-repeat`,
              backgroundPosition: "center",
              backgroundSize: "contain",
            }}>
        <motion.div initial={{opacity:0, y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.5,ease:'easeInOut'}} className="flex items-center justify-center flex-col gap-5 -mt-0 group ">
  <div
    className="relative text-[38px] leading-[120px] opacity-[calc(10/10)] text-white  font-bold group h-[100px] w-[100px] flex flex-col items-center justify-center"
  >
    <div
      className="absolute inset-0 bg-[#2b2b2b]  bg-center bg-contain  bg-no-repeat transition-transform duration-500 transform group-hover:rotate-90 rounded-full"
      style={{
        backgroundImage: `url(${ProcessPlug})`,

      }}
    ></div>
    <div className="relative z-10 font-DM-Sans ">01</div>
  </div>
  <h3 className="text-white font-bold text-2xl">Our Networks</h3>
  <p className="text-xs font-medium text-gray-200 text-center">All the Lorem Ipsum generators on the Internet tend to repeat predefined.</p>
</motion.div>
        <motion.div initial={{opacity:0, y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.5,ease:'easeInOut'}} className="flex items-center justify-center flex-col gap-5 group lg:mt-28">
  <div
    className=" mt-10 relative text-[38px] leading-[120px] opacity-[calc(10/10)] text-white  font-bold group h-[100px] w-[100px] flex flex-col items-center justify-center"
  >
    <div
      className="absolute inset-0 bg-[#2b2b2b]  bg-center bg-contain  bg-no-repeat transition-transform duration-500 transform group-hover:rotate-90 rounded-full"
      style={{
        backgroundImage: `url(${ProcessPlug})`,

      }}
    ></div>
    <div className="relative z-10 font-DM-Sans ">02</div>
  </div>
  <h3 className="text-white font-bold text-2xl">Charge Points</h3>
  <p className="text-xs font-medium text-gray-200 text-center">All the Lorem Ipsum generators on the Internet tend to repeat predefined.</p>
</motion.div>
        <motion.div initial={{opacity:0, y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.5,ease:'easeInOut'}} className="flex items-center justify-center flex-col gap-5 group mt-3">
  <div
    className="relative text-[38px] leading-[120px] opacity-[calc(10/10)] text-white  font-bold group h-[100px] w-[100px] flex flex-col items-center justify-center"
  >
    <div
      className="absolute inset-0 bg-[#2b2b2b]  bg-center bg-contain  bg-no-repeat transition-transform duration-500 transform group-hover:rotate-90 rounded-full"
      style={{
        backgroundImage: `url(${ProcessPlug})`,

      }}
    ></div>
    <div className="relative z-10 font-DM-Sans ">03</div>
  </div>
  <h3 className="text-white font-bold text-2xl">EV Stations</h3>
  <p className="text-xs font-medium text-gray-200 text-center">All the Lorem Ipsum generators on the Internet tend to repeat predefined.</p>
</motion.div>



              </div>

        </div>

        <div className="h-[400px]  hidden xl:flex lg:block overflow-hidden w-full relative flex-col items-center justify-center" style={{
              background: ` url(${BG4}) no-repeat`,
              backgroundPosition: "left",
              backgroundSize: "cover",
              opacity:1

            }}>
              <div className="absolute inset-0 w-ful z-0 xl:block hidden  " style={{
              background: `url(${ChargerImg}) no-repeat`,
              backgroundPosition: "right",
              backgroundSize: "contain",
              opacity:1

            }}>
              

              </div>
              <div className="h-full  absolute inset-0 w-full flex items-center justify-start z-100 ">
                <div className="xl:w-9/12 w-full transform lg:rotate-[15deg]   rotate-0 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-20  justify-between ml-10 -mt-28 ">

                {features.map((obj, index) => (
        <div
          key={index}
          onMouseEnter={() => setHoverIndex(index)}
          onMouseLeave={() => setHoverIndex(null)}
          className={`relative ${index === 1 || index === features.length - 1 ? 'xl:group' : ''} w-full `}
        >
          <BoltIcon className="h-5 w-5 fill-primary cursor-pointer" />
          {(index === 0 || index === 2 || hoverIndex === index) && (
            <div className="bg-white/50 isolate rounded-lg xl:-rotate-[15deg] lg:-rotate-[15deg] absolute px-5 py-5 w-[250px] z-10 flex flex-col gap-2 ">
              <h5 className="font-bold font-Jakarta">{obj.title}</h5>
              <p className="text-xs font-DM-Sans">{obj.description}</p>
            </div>
          )}
        </div>
      ))}

                </div>

              </div>

        </div>

        <div className="h-[650px] md:h-[400px]  w-full  xl:hidden lg:hidden grid md:grid-cols-2 grid-cols-1  bg-[#1a1919]">
          {
            features.map((obj,index)=>(
              <div key={obj.id} className="flex flex-col gap-5 items-center justify-center">
                
                
                <div className="bg-white/50 isolate rounded-lg xl:-rotate-[15deg] lg:-rotate-[15deg] absolute px-5 py-5 w-[250px] z-10 flex flex-col gap-2 ">
                <BoltIcon className="h-5 w-5 fill-primary"/>
              <h5 className="font-bold font-Jakarta">{obj.title}</h5>
              <p className="text-xs font-DM-Sans">{obj.description}</p>
            </div>
              </div>
            ))
          }

        </div>

      </section>

    </div>
  );
};

export default Home;
