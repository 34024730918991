import React from 'react'
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import FooterBG from '../../assets/Images/footer-bg-img-1.jpg'
import { Link } from 'react-router-dom';
import PDFMOC from '../../assets/Images/pdf-moc.png'
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { LinkedIn } from '@mui/icons-material';
// import PdfFile from '../../assets/Images/EV-Aggregation.pdf'

const Footer = () => {
  return (
    <footer className='h-fit bg-[#171516] flex flex-col gap-2'>
        <div className=' flex flex-col items-center  w-full'>
            <div className='bg-primary xl:w-3/5 lg:w-3/4 px-5 py-8 flex xl:flex-row lg:flex-row md:flex-row flex-col gap-4 justify-between items-center'>
                <h4 className='font-bold lg:text-xl md:text-[15px] text-white'>Safe & Easy To Use Charging</h4>
                <HeadsetMicIcon className='rounded-full animate-pulse text-white bg-black p-1' fontSize='large'/>
                
                    <h4 className='font-Jakarta font-bold xl:text-xl text-white lg:text-xl md:text-[15px] text-[13px] '>Feel Free to Call Us: <a href="tel:+919962993939" className='text-black hover:text-white'>
                    +91-9962993939</a></h4>

                

            </div>
        </div>
        <div className='px-5  bg-center bg-cover grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-10 relative bg py-10 ' >
            <div className='flex flex-col gap-5'>
                <h4 className='text-[20px] font-bold font-Montserrat text-primary'>Contact</h4>
                <div className='flex flex-col gap-5'>
                    <h5 className='text-[18px] font-bold font-Jakarta text-white'>Address</h5>
                    <p className='text-blue-gray-400 font-semibold text-[15px] font-DM-Sans'>
                    190-192, unit-19, Second Floor, Hameed Complex. Anna Salai, Chennai -600006.
                    </p>
                </div>
                <div className='flex flex-col gap-5'>
                    <h5 className='text-[18px] font-bold font-Jakarta text-white'>Call us on</h5>
                    <p className='text-blue-gray-400 font-semibold text-[15px] font-DM-Sans hover:text-primary'>
                    <a href="tel:+919962993939">+91-9962993939</a>
                    </p>
                </div>
                <div className='flex flex-col gap-5'>
                    <h5 className='text-[18px] font-bold font-Jakarta text-white'>Email us</h5>
                    <p className='text-blue-gray-400 font-semibold text-[15px] font-DM-Sans hover:text-primary'>
                    <a href="mailto:milin@india1charger.com">milin@india1charger.com</a>
                    </p>
                </div>

            </div>
            <div className='flex flex-col gap-5'>
            <h4 className='text-[20px] font-bold font-Montserrat text-primary'>Quick Links</h4>
            <ul className="flex flex-col gap-5">
                <li className='text-[15px] text-blue-gray-400 hover:text-primary font-semibold font-DM-Sans'>
                    <Link to="/" >Home</Link>
                </li>
                <li className='text-[15px] text-blue-gray-400 hover:text-primary font-semibold font-DM-Sans'>
                    <Link to="/" >About</Link>
                </li>
                <li className='text-[15px] text-blue-gray-400 hover:text-primary font-semibold font-DM-Sans'>
                    <Link to="/products" >Products</Link>
                </li>
                <li className='text-[15px] text-blue-gray-400 hover:text-primary font-semibold font-DM-Sans'>
                    <Link to="/terms-and-conditions" >Terms and Conditions</Link>
                </li>
                <li className='text-[15px] text-blue-gray-400 hover:text-primary font-semibold font-DM-Sans'>
                    <Link to="/privacy-policy" >Privacy Policy</Link>
                </li>
            </ul>

            </div>
            <div className='flex flex-col gap-5'>
            <h4 className='text-[20px] font-bold font-Montserrat text-primary'>Products</h4>
            <ul className="flex flex-col gap-5">
                <li className='text-[15px] text-blue-gray-400 hover:text-primary font-semibold font-DM-Sans'>
                    <Link to="/products/#ac-chargers" >AC Chargers</Link>
                </li>
                <li className='text-[15px] text-blue-gray-400 hover:text-primary font-semibold font-DM-Sans'>
                    <Link to="/products/#dc-chargers" >DC Chargers</Link>
                </li>
                <ul>
                <h4 className='text-[20px] font-bold font-Montserrat text-primary'>Social Links</h4>
                <>
                <ul className='flex flex-row gap-5'>
                <li><a href="https://www.linkedin.com/company/india1-charger/about/"><LinkedIn color='white' className='text-gray-300 hover:text-light-blue-800'/></a></li>

                </ul>
                

                </>
                    
                </ul>
        
            </ul>

            </div>
            <div className='flex flex-col gap-10 items-center justify-center'>
                <div className='p-1 border border-primary rounded-lg'>
                    <img src={PDFMOC} alt={PDFMOC.jpg} />
                </div>
                <button className='text-[15px] font-bold font-DM-Sans flex gap-2 text-blue-gray-400 border border-blue-gray-400 px-3 py-3 items-center rounded-lg hover:border-primary group hover:text-primary'>
                    <a href={`EV-Aggregation.pdf`}  download={true} className='flex items-center gap-2'>Download Brouchure <ArrowDownTrayIcon className='fill-blue-gray-400 group-hover:fill-primary h-5 w-5'/></a>
                    
                </button>

            </div>

        </div>
        <div className='flex items-center justify-center border-t border-t-primary z-10  bottom-0 p-2 text-white font-Jakarta text-[14px] font-medium text-center'>
            <p>Copyrights © India1 Chargers 2024. All Rights Reserved</p>
        </div>
    </footer>
  )
}

export default Footer