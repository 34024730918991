import React from 'react'
import { Helmet } from 'react-helmet'
import BannerSection from '../../Components/Banner'
import BgIMg from '../../assets/Images/s2.jpg'
import M1 from '../../assets/Images/12.png'
import {motion} from 'framer-motion'
import PlayStore from "../../assets/Images/googleplay-light-1.png";
import AppStore from "../../assets/Images/appstore-light-1.png";
import { ArrowPathIcon, ChatBubbleLeftRightIcon, Cog6ToothIcon, DevicePhoneMobileIcon, FingerPrintIcon, MapPinIcon, PencilIcon, PresentationChartBarIcon, ShieldCheckIcon, WalletIcon } from '@heroicons/react/24/solid'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { Celebration } from '@mui/icons-material'
import M2 from '../../assets/Images/14.png'
import M3 from '../../assets/Images/11.png'


const AppIntro = () => {
    const steps = [
        {
          "action": "Visit the App Store or Google Play Store",
          "description": "Search for 'India1 Charger' in the App Store (for iOS devices) or Google Play Store (for Android devices)."
        },
        {
          "action": "Click on 'Download' or 'Get'",
          "description": "Once you've found the India1 Charger app, click on the 'Download' or 'Get' button to start the download process."
        },
        {
          "action": "Install the App",
          "description": "Once the app has finished downloading, click on the 'Install' button to install it on your device."
        },
        {
          "action": "Open the App",
          "description": "Once the installation is complete, open the India1 Charger app to start exploring its features."
        }
      ]
  return (
    <>
    <Helmet>
        <title>Download the App</title>
    </Helmet>
    <BannerSection pagetitle={"Download the App"}/>
    <section className='min-h-screen  w-full bg-no-repeat relative xl:px-20 lg:px-20 px-5 ' >
    {/* style={{
        background:`url(${BgIMg}) no-repeat`,
        backgroundSize:'cover',
        backgroundPosition:'center'

    }} */}
        {/* <div className='absolute bg-black inset-0 opacity-[0.5]'>
        </div> */}

        <div className='z-10 absolute inset-0 grid grid-cols-1 xl:grid-cols-2 md:grid-cols-1 lg:grid-cols-2  items-center px-10  '>
            <div className='flex flex-col gap-10 md:order-2 order-2 xl:order-1 lg:order-1'>
              
                <motion.h2 className="lg:text-5xl md:text-2xl text-xl text-left leading-snug font-bold font-Jakarta " initial={{opacity:0,y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}}>
                Welcome to the future of charging!
        </motion.h2>
        <p className="text-[16px] text-justify font-DM-Sans text-blue-gray-400 ">
        Download the India1 Charger app today and unlock a world of convenience and reliability. Whether you’re at home, at work, or on the go, our app makes it easier than ever to find and access charging stations, reserve spots in advance, and manage your charging needs with ease.
            
        </p>
        <motion.div initial={{opacity:0}} whileInView={{opacity:1}} viewport={{once:false}} transition={{duration:0.5,ease:'easeInOut'}} className="gap-5 flex w-full">
              <button className="bg-primary px-5 py-2 h-[50px] hover:bg-black rounded-lg"><img src={PlayStore} alt="googleplay.png" className="h-full w-full object-contain" /></button>
              <button className="bg-black px-5 py-2 h-[50px] hover:bg-primary rounded-lg"><img src={AppStore} alt="appstore.png" className="h-full w-full object-contain" /></button>
            </motion.div>

            </div>
            <div className='flex flex-col items-center justify-center xl:order-2 lg:order-2'>
            <div className='xl:h-[600px] lg:h-[450px] lg:w-[450px] md:h-[350px] md:w-[350px] h-[200px] w-[200px] xl:w-[600px] bg-gradient-to-br animate-pulse from-primary via-green-400 to-white rounded-full relative flex flex-col items-center justify-center'>
                <div className='h-[500px]'>
                        <img src={M1} alt="mobile1.png" className='h-full w-full object-contain' />
                </div>

            </div>

            </div>
            

        </div>
        <div>

        </div>
     
    </section>
    <section className='flex flex-col items-center justify-center'>
    <div className='grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 py-5 items-center justify-center w-[80%] gap-10  '>
        <motion.div initial={{opacity:0,y:150}} whileInView={{opacity:1,y:0}} whileHover={{y:-10}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}} className='flex flex-col gap-5 items-center justify-center'>
            <DevicePhoneMobileIcon className='h-16 w-16 bg-primary px-2 py-2 fill fill-white rounded-full'/>
            <h4 className='font-Jakarta font-bold text-xl text-center'>All-in-One EV Solution</h4>
        </motion.div>
        <motion.div initial={{opacity:0,y:150}} whileInView={{opacity:1,y:0}} whileHover={{y:-10}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}} className='flex flex-col gap-5 items-center justify-center'>
            <ShieldCheckIcon className='h-16 w-16 bg-primary px-2 py-2 fill fill-white rounded-full'/>
            <h4 className='font-Jakarta font-bold text-xl text-center'>Real-Time Charger Availability</h4>
        </motion.div>
        <motion.div initial={{opacity:0,y:150}} whileInView={{opacity:1,y:0}} whileHover={{y:-10}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}} className='flex flex-col gap-5 items-center justify-center'>
            <PencilIcon className='h-16 w-16 bg-primary px-2 py-2 fill-white rounded-full'/>
            <h4 className='font-Jakarta font-bold text-xl text-center'>Smart Trip Planner</h4>
        </motion.div>
        <motion.div initial={{opacity:0,y:150}} whileInView={{opacity:1,y:0}} whileHover={{y:-10}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}} className='flex flex-col gap-5 items-center justify-center'>
            <WalletIcon className='h-16 w-16 bg-primary px-2 py-2  fill-white rounded-full'/>
            <h4 className='font-Jakarta font-bold text-xl text-center'>Built-In Wallet</h4>
        </motion.div>
 
    </div>
    </section>
    <section className=' py-10 flex flex-col items-center justify-center'>
<div className='flex flex-row flex-wrap justify-center bg-white w-[80%]'>
<div className='lg:w-1/2 w-full'>
        <motion.div initial={{opacity:0,scale:0}} whileInView={{opacity:1,scale:1}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}} className='h-[500px]'>
                        <img src={M2} alt="mobile1.png" className='h-full w-full object-contain' />
                </motion.div>


        </div>
        <div className='flex flex-col gap-5 lg:w-1/2 w-full'>
            <span className='bg-primary/50 px-5 py-2 font-DM-Sans text-[13px] font-semibold rounded-lg w-fit'>About App</span>
            <motion.h2 className="lg:text-3xl md:text-2xl text-xl text-left leading-snug font-bold font-Jakarta " initial={{opacity:0,y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}}>
            Charging Station Aggregation
        </motion.h2>
        <p className='text-gray-500 font-DM-Sans text-[17px]'>
        Our app aggregates charging stations from various providers, allowing you to easily locate available stations near you.
        </p>
        <h5 className='font-semibold text-[20px] font-Jakarta'>Feature Integration</h5>
        <ul className='flex flex-col gap-5 font-DM-Sans text-[17px] text-gray-500 list-disc'>
            <li>Fringilla risus nec, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien</li>
            <li>Quaerat sodales sapien undo euismod purus blandit</li>
            <li>Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium purus</li>
        </ul>

        </div>
</div>

    </section>

    <section className='flex flex-col items-center gap-5 py-10'>
    <span className='bg-primary/50 px-5 py-2 font-DM-Sans text-[13px] font-semibold rounded-lg w-fit text-center mx-5'>Why India1 Charger is the Best Choice for EV Owners</span>
    <p className='text-gray-500 font-DM-Sans text-[17px] xl:w-1/2 lg:w-1/2 md:w-1/2 w-full text-center'>Are you tired of juggling multiple apps and services for your electric vehicle? Say hello to India1 Charger, the ultimate solution for all your EV needs. Here's why India1 Charger stands out from the crowd</p>

    <div className='w-4/5  grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-5 cursor-pointer'>

    <div className='lg:h-[250px] md:h-[250px] h-full py-5 relative bg-white flex flex-col gap-5 items-center group justify-center px-5 shadow-lg rounded-tl-xl rounded-br-xl'>
        <div className='absolute bg-primary inset-0 h-0 group-hover:h-full transition-all ease-in-out duration-300 rounded-tl-xl rounded-br-xl'></div>
        <PresentationChartBarIcon className='h-7 w-7 fill-primary  z-10  group-hover:fill-white'/>
        <h3 className='font-Jakarta text-[18px] group-hover:text-white font-bold text-center z-10'>All-in-One Aggregator App</h3>
        <p className='text-gray-500 group-hover:text-white font-DM-Sans text-[14px] z-10'>
        From insurance to roadside assistance, India1 Charger covers it all. No more switching between apps—everything you need is right here.
        </p>
        

    </div>
    <div className='lg:h-[250px] md:h-[250px] h-full py-5 relative bg-white flex flex-col gap-5 items-center group justify-center px-5 shadow-lg rounded-tl-xl rounded-br-xl'>
        <div className='absolute bg-primary inset-0 h-0 group-hover:h-full transition-all ease-in-out duration-300 rounded-tl-xl rounded-br-xl'></div>
        <DevicePhoneMobileIcon className='h-7 w-7 fill-primary  z-10  group-hover:fill-white'/>
        <h3 className='font-Jakarta text-[18px] group-hover:text-white font-bold text-center z-10'>Comprehensive EV Services</h3>
        <p className='text-gray-500 group-hover:text-white font-DM-Sans text-[14px] z-10'>
        Whether it’s car assistance, car accessories, or finding the nearest charging station, India1 Charger has you covered.
        </p>
        

    </div>
    <div className='lg:h-[250px] md:h-[250px] h-full py-5 relative bg-white flex flex-col gap-5 items-center group justify-center px-5 shadow-lg rounded-tl-xl rounded-br-xl'>
        <div className='absolute bg-primary inset-0 h-0 group-hover:h-full transition-all ease-in-out duration-300 rounded-tl-xl rounded-br-xl'></div>
        <MapPinIcon className='h-7 w-7 fill-primary  z-10  group-hover:fill-white'/>
        <h3 className='font-Jakarta text-[18px] group-hover:text-white font-bold text-center z-10'>Smart Features</h3>
        <p className='text-gray-500 group-hover:text-white font-DM-Sans text-[14px] z-10'>
        With real-time maps, trip planning, a convenient shop, a built-in wallet, and up-to-the-minute charger availability, India1 Charger takes the hassle out of EV ownership.
        </p>
        

    </div>
    <div className='lg:h-[250px] md:h-[250px] h-full py-5 relative bg-white flex flex-col gap-5 items-center group justify-center px-5 shadow-lg rounded-tl-xl rounded-br-xl'>
        <div className='absolute bg-primary inset-0 h-0 group-hover:h-full transition-all ease-in-out duration-300 rounded-tl-xl rounded-br-xl'></div>
        <Cog6ToothIcon className='h-7 w-7 fill-primary  z-10  group-hover:fill-white'/>
        <h3 className='font-Jakarta text-[18px] group-hover:text-white font-bold text-center z-10'>User-Centric Design</h3>
        <p className='text-gray-500 group-hover:text-white font-DM-Sans text-[14px] z-10'>
        Filter chargers by type (AC or DC) and see instantly if a station is full, unserviceable, or available. Our intuitive icons and color codes make it easy to find exactly what you need.
        </p>
        

    </div>
    

    </div>

    </section>
    <section className=' py-10 flex flex-col items-center justify-center'>
<div className='flex flex-row flex-wrap justify-center bg-white w-[80%]'>
<div className='lg:w-1/2 w-full flex flex-col items-center justify-center'>
        <motion.div initial={{opacity:0,scale:0}} whileInView={{opacity:1,scale:1}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}} className='h-[500px]'>
                        <img src={M3} alt="mobile1.png" className='h-full w-full object-contain' />
                </motion.div>
                <div className="gap-5 flex w-full items-center justify-center">
              <button className="bg-primary px-5 py-2 h-[40px] hover:bg-black rounded-lg"><img src={PlayStore} alt="googleplay.png" className="h-full w-full object-contain" /></button>
              <button className="bg-black px-5 py-2 h-[40px] hover:bg-primary rounded-lg"><img src={AppStore} alt="appstore.png" className="h-full w-full object-contain" /></button>
            </div>


        </div>
        <div className='flex flex-col gap-5 lg:w-1/2 w-full py-5'>
            <span className='bg-primary/50 px-5 py-2 font-DM-Sans text-[13px] font-semibold rounded-lg w-fit'>Download the App</span>
            <motion.h2 className="lg:text-3xl md:text-2xl text-xl text-left leading-snug font-bold font-Jakarta " initial={{opacity:0,y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}}>
            How to Download the India1 Charger App
        </motion.h2>
        <p className='text-gray-500 font-DM-Sans text-[17px]'>
        Our app aggregates charging stations from various providers, allowing you to easily locate available stations near you.
        </p>
        <div className=' grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-5 cursor-pointer'>

            {
                steps.map((obj,index)=>(
                    <div className='h-[200px] relative bg-white flex flex-col gap-5 items-center group justify-center px-5 shadow-lg rounded-tl-xl rounded-br-xl'>
    <div className='absolute bg-primary inset-0 h-0 group-hover:h-full transition-all ease-in-out duration-300 rounded-tl-xl rounded-br-xl'></div>
    {/* <PresentationChartBarIcon className='h-7 w-7 fill-primary  z-10  group-hover:fill-white'/> */}
    <h3 className='font-Jakarta text-[18px] group-hover:text-white font-bold text-center z-10'>{obj.action}</h3>
    <p className='text-gray-500 group-hover:text-white font-DM-Sans text-[14px] z-10 text-center'>
    {obj.description}
    </p>
    

</div>
                ))
            }
            </div>


        </div>
</div>

    </section>
    </>
  )
}

export default AppIntro