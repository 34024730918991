import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
 
import Slider from "react-slick";

const CustomCarousel = ({customSetting,children}) => {
  return (
    <div>
      <Slider {...customSetting}>
        {children}
      </Slider>
    </div>
  )
}

export default CustomCarousel