import { BoltIcon } from '@heroicons/react/24/solid'
import React, { useEffect,Component, } from 'react'
import { Helmet } from 'react-helmet'
import {motion} from 'framer-motion'
import AboutBG from '../../assets/Images/aboutbg.jpg'
import AboutBG2 from '../../assets/Images/about-image6-1.jpg'
import FB1 from '../../assets/Images/1.png'
import FB2 from '../../assets/Images/2.png'
import FB3 from '../../assets/Images/3.png'
import FB4 from '../../assets/Images/4.png'
import BannerSection from '../../Components/Banner'

const Aboutus = () => {

    const featureBoxData = [
        {
          id: 1,
          title: "Home Charging",
          img: FB1
        },
        {
          id: 2,
          title: "Commercial System",
          img: FB2
        },
        {
          id: 3,
          title: "Public Stations",
          img: FB3
        },
        {
          id: 4,
          title: "Work Stations",
          img: FB4
        }
      ];

      const whyChooseData =[
        {
          id: 1,
          title: "Innovative Technology",
          description: "Our charging station aggregation app utilizes cutting-edge technology to connect you with charging stations near you, ensuring that you always have access to power when you need it most."
        },
        {
          id: 2,
          title: "Quality Products",
          description: "We take pride in offering a range of high-quality AC and DC chargers designed to meet the diverse needs of our customers. Whether you're looking for a dual charger for your home or a high-end model for your electric vehicle, we have you covered."
        },
        {
          id: 3,
          title: "Exceptional Customer Service",
          description: "At India1 Charger, customer satisfaction is our top priority. Our team is dedicated to providing you with the support and assistance you need to make the most of your charging experience."
        }
      ]

      const valuesData  =  [
        {
          id: 1,
          title: "Innovation",
          description: "We're committed to pushing the boundaries of technology to create innovative solutions that enhance your charging experience."
        },
        {
          id: 2,
          title: "Quality",
          description: "We believe in providing products and services of the highest quality, ensuring that you can rely on India1 Charger for all your charging needs."
        },
        {
          id: 3,
          title: "Accessibility",
          description: "We strive to make charging accessible to everyone, regardless of location, device, or circumstance."
        },
        {
          id: 4,
          title: "Customer Satisfaction",
          description: "Your satisfaction is our top priority. We're dedicated to providing exceptional customer service and support to ensure that you have the best possible experience with India1 Charger."
        }
      ];

      const aboutdata = [
        {
          "id": 1,
          "heading": "Real-Time Charger Availability",
          "description": "Instantly find available charging stations."
        },
        {
          "id": 2,
          "heading": "Smart Trip Planning",
          "description": "Plan your routes with ease."
        },
        {
          "id": 3,
          "heading": "Seamless Purchases",
          "description": "Buy chargers directly through our app."
        },
        {
          "id": 4,
          "heading": "In-App Payments",
          "description": "Conveniently manage your payments."
        }
      ]
      
      

  return (
    <>
        <Helmet>
        <title>About us</title>
        </Helmet>
        <BannerSection pagetitle={"About us"}/>
        <section className='h-full py-20 grid lg:grid-cols-2 grid-cols-1 gap-5 px-5 lg:px-10 md:px-10'>
        <div className="flex flex-col gap-5 items-start justify-center">
        <div className="flex flex-row gap-4 items-center">
          <div className="w-8 h-2 border-primary border rounded-full ">
            <div className="w-full h-full bg-primary animate-progressHorizontal"></div>
          </div>
          <motion.span initial={{opacity:0}} whileInView={{opacity:1}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}} className="text-[15px] font-DM-Sans uppercase font-semibold">
            About us
          </motion.span>

          <BoltIcon className="h-5 fill-primary" />
        </div>
        <motion.h2 className="lg:text-5xl md:text-2xl text-xl  leading-snug font-bold font-Jakarta" initial={{opacity:0,y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}}>
        Electric Charger Provider
        </motion.h2>
        <p className="text-[16px] font-DM-Sans text-blue-gray-400 text-justify">
        Welcome to India1 Charger, your ultimate destination for all things electric vehicle. We are dedicated to revolutionizing the EV experience with our comprehensive app, offering everything from real-time charger availability and smart trip planning to seamless charger purchases and in-app payments.
            
        </p>

            </div>
            <div className="flex flex-col gap-5 items-start justify-center">
                <motion.h2 initial={{opacity:0,y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}}  className="lg:text-6xl md:text-2xl text-2xl text-center leading-snug font-bold font-Jakarta text-primary"><span className='lg:text-3xl md:2xl text-xs  '>Our Mission</span></motion.h2>
                <p className="text-[16px] font-DM-Sans text-blue-gray-400 text-justify">
                Our mission is to simplify and enhance your EV journey by providing a one-stop solution for all your charging needs. With intuitive features, user-friendly design, and a commitment to excellence, India1 Charger is here to power your drive towards a sustainable future.
Join us and experience the future of electric vehicle convenience today!
            
        </p>

            </div>

        </section>
        <section className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 bg-[#f0f4f6]'>
            <div className='bg-contain bg-left h-[500px]' style={{background:`url(${AboutBG2}) no-repeat`, backgroundSize:'800px 900px'}}>

            </div>
            <div className="flex flex-col gap-5 items-start justify-center h-[500px] px-10">
        <div className="flex flex-row gap-4 items-center">
          <div className="w-8 h-2 border-primary border rounded-full ">
            <div className="w-full h-full bg-primary animate-progressHorizontal"></div>
          </div>
          <motion.span initial={{opacity:0}} whileInView={{opacity:1}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}} className="text-[15px] font-DM-Sans uppercase font-semibold">
            About us
          </motion.span>

          <BoltIcon className="h-5 fill-primary" />
        </div>
        <motion.h2 className="lg:text-5xl md:text-2xl text-xl  leading-loose font-bold font-Jakarta mb-5" initial={{opacity:0,y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}}>
        At India1 Charger, we're transforming the EV experience
        </motion.h2>
        <p className="text-[16px] font-DM-Sans text-blue-gray-400 text-justify">
          <ul className='flex flex-col space-y-2'>
            {
              aboutdata.map((obj,index)=><li key={obj.id}>
                <b>{obj.heading}:</b> {obj.description}
              </li>)
            }
            </ul>

       
        <br /><br />
        Simplify your journey with our one-stop solution and drive towards a sustainable future.
        </p>


            </div>

        </section>
        <section
        className="min-h-screen relative  my-10 flex flex-col items-center justify-center"
        style={{
          background: `url(${AboutBG}) no-repeat`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className='w-9/12 grid gap-5 grid-cols-1 xl:grid-cols-4 md:grid-cols-2 lg:grid-cols-4 items-center justify-center'>
        {
            featureBoxData.map((obj,index)=>(
                <motion.div key={obj.id} initial={{opacity:0, y:200}} whileHover={{y:-20}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}} className='flex flex-col gap-10 justify-center py-5 rounded-lg items-center bg-primary group hover:bg-black'>
                    <div className='h-[60px] w-[60px]'>
                        <img src={obj.img} alt={obj.title} className='h-full w-full object-contain' />
                    </div>
                    <h4 className='xl:text-2xl lg:text-[18px] text-ellipsis group-hover:text-white font-bold font-Jakarta text-white'>{obj.title}</h4>
                </motion.div>
            ))
        }

        </div>


        </section>
        <section className='flex flex-col items-center gap-10 justify-center py-10'>
        <motion.h2 className="lg:text-5xl md:text-2xl text-xl text-center  leading-snug font-bold font-Jakarta " initial={{opacity:0,y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}}>
        Why Choose India1 Charger
        </motion.h2>
        <p className="text-[16px] text-center font-DM-Sans text-blue-gray-400 ">
        At India1 Charger, we’re guided by a set of core values that shape everything we do
            
        </p>
            <div className='w-9/12  grid gap-5 grid-cols-1 xl:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 items-center justify-center '>
            {
                whyChooseData.map((obj,index)=>(
                    <motion.div key={obj.id} initial={{opacity:0,y:150}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.8, ease:'linear'}} className='flex bg-white flex-col gap-8 items-center shadow-xl rounded-lg border py-5 px-3 xl:h-[300px] md:h-[350px] lg:[h-300px] hover:border-primary'>
                        <BoltIcon className='h-7 w-7 fill-primary animate-pulse'/>
                        <h4 className='font-Jakarta font-bold text-xl text-center'>{obj.title}</h4>
                        <p className="text-[16px]  font-DM-Sans text-blue-gray-400 ">
     {obj.description}
        </p>

                    </motion.div>
                ))
            }

            </div>
        </section>
        <section className='flex flex-col items-center gap-10 justify-center py-10'>
        <motion.h2 className="lg:text-5xl md:text-2xl text-xl text-center  leading-snug font-bold font-Jakarta " initial={{opacity:0,y:100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}}>
        Our Values
        </motion.h2>
        <p className="text-[16px] text-center font-DM-Sans text-blue-gray-400 ">
        At India1 Charger, we’re guided by a set of core values that shape everything we do
            
        </p>
            <div className='w-9/12  grid gap-5 grid-cols-1 xl:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 items-center justify-center '>
            {
                valuesData.map((obj,index)=>(
                    <motion.div key={obj.id} initial={{opacity:0,y:150}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{duration:0.8, ease:'linear'}} className='flex bg-white flex-col gap-8 items-center shadow-xl rounded-lg border py-5 px-3 h-[300px] hover:border-primary'>
                        <BoltIcon className='h-7 w-7 fill-primary animate-pulse'/>
                        <h4 className='font-Jakarta font-bold text-xl text-center'>{obj.title}</h4>
                        <p className="text-[16px]  font-DM-Sans text-blue-gray-400 ">
     {obj.description}
        </p>

                    </motion.div>
                ))
            }

            </div>
        </section>
   
        
    </>
  )
}

export default Aboutus