import React, { useEffect } from 'react'
import Header from './Components/Header'
import Home from './Screens/Home'
import { Route, Routes } from 'react-router-dom'
import ProductLandingPage from './Screens/ProductLanding'
import Products from './Screens/Products'
import ScrollToTop from './Scroll'
import TermsandConditions from './Screens/T&c'
import PrivacyPolicy from './Screens/PrivacyPolicy'
import Footer from './Components/Footer'
import Aboutus from './Screens/Aboutus'
import AppIntro from './Screens/AppPage'
import ACChargers from './Screens/ACChargers'
import ContactUs from './Screens/Contact'
import CheckoutScreen from './Screens/CheckoutPage'
import PageNotFound from './Screens/PageNotFound'
import { BoltIcon } from '@heroicons/react/24/solid'
import './App.css'

const App = () => {


 
  return (
    <div>
      <Header/>
      <ScrollToTop/>
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/about-us' element={<Aboutus/>} />
      <Route path='/products' element={<Products/>} />
      <Route path='/download-app' element={<AppIntro/>} />
      <Route path='/terms-and-conditions' element={<TermsandConditions/>} />
      <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
      <Route path='products/:productid' element={<ProductLandingPage/>} />
      {/* <Route path='ac-chargers' element={<ACChargers/>} />
      <Route path='ac-chargers' element={<ACChargers/>} /> */}
      <Route path='contact' element={<ContactUs/>} />
      <Route path='checkout' element={<CheckoutScreen/>} />
      <Route path='*' element={<PageNotFound/>} />
    </Routes>
    <Footer/>
    </div>
  )
}

export default App