import React from 'react'
import MapImg from '../../assets/Images/map2.png'
import { BoltIcon } from '@heroicons/react/24/solid'
import CircleIcon from '@mui/icons-material/Circle';

const BannerSection = ({pagetitle}) => {
  return (
    <section className='h-[300px] flex flex-col items-center gap-3 justify-center bg-[#f0f4f6] relative' style={{
        background:`url(${MapImg}) no-repeat`,
        backgroundPosition:'right',
        backgroundSize:'500px 400px'
    }}>
        <h2 className='animate-zoomin font-Jakarta font-bold xl:text-5xl lg:text-5xl md:text-3xl text-2xl'>{pagetitle}</h2>
        <BoltIcon className='absolute h-36 left-0 -bottom-14 fill-primary animate-pulse'/>
        <div className='flex flex-row gap-2 items-center'>
            <a href="/" className='font-semibold font-DM-Sans hover:text-primary'>Home</a>
            <CircleIcon  className='text-primary animate-ping' sx={{height:'12px'}}/>
            <a href='#' className='font-DM-Sans text-blue-gray-400 font-medium'>{pagetitle}</a>
        </div>

    </section>
  )
}

export default BannerSection