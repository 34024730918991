import React, { useEffect, useState } from "react";
import Logo from "../../assets/Images/logo.png";
import SearchIcon from "@mui/icons-material/Search";
import { moonIcon } from "@heroicons/react/outline";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { Menu, MenuHandler } from "@material-tailwind/react";
import { Bars3BottomLeftIcon, ChevronDownIcon, ChevronUpIcon, XMarkIcon } from "@heroicons/react/24/solid";


const Header = () => {
  const [open, setopen] = useState();
  const toggleDrawer = (event) => () => {
    setopen(event);
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth/4);
  const [showDrawer, setShowDrawer] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [active, SetActive] = useState(false);

  const [darkMode,setDarkMode]=useState(false);

  useEffect(()=>{
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);

  },[])

  useEffect(()=>{
    document.documentElement.classList.toggle('dark',darkMode);
    localStorage.setItem('darkMode',darkMode)
  },[darkMode])

  const toggleDarkMode = ()=>{
    setDarkMode((prevMode)=>!prevMode);
  }


  useEffect(()=>{
    const mediaQuery = window.matchMedia("(max-width:770px)");

    const handleMediaQueryChange = (event)=>{
        setShowDrawer(event.matches)
        setIsOpen(false)
    }

    handleMediaQueryChange(mediaQuery)

    console.log(mediaQuery);

    mediaQuery.addEventListener('change',handleMediaQueryChange)

    return()=>mediaQuery.removeEventListener('change',handleMediaQueryChange)
  },[])

  useEffect(() => {});

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);



  const [isOpen, setIsOpen] = useState(false);
  const [isMobileMenuOpen,setIsMobileMenuOpen]=useState(false)
  const [isHoverDropDown,setIsHoverDropDown]=useState(false);

  const navList = [
    {
      name:'Home',path:'/',
    },
    {
      name:'About us',path:'/about-us',
    },
    {
      name:'Our Products',path:'/Products', 
      // dropdown:true,items:[
      //   {name:'Ac Chargers',path:'ac-chargers'},
      //   {name:'Ac Chargers',path:'ac-chargers'},
      //   {name:'Ac Chargers',path:'ac-chargers'},
      // ]
    },
    {
      name:'Download App',path:'/download-app',
    },
    // {
    //   name:'T&C',path:'/terms-and-conditions',
    // },
    // {
    //   name:'Privacy Policy',path:'/privacy-policy',
    // },
    
  ]


  const toggleDropdown = () => {
    setIsHoverDropDown(!isHoverDropDown);
  };


  return (
    <>

    {/* <header className={`shadow-lg shadow-slate-100 h-20 px-10 bg-white  ${isScrolled ? 'z-50 fixed w-full top-0 animate-slideindown  shadow-none':''} flex flex-col justify-center`}>
      <div className="flex flex-row justify-between">
    <div className="h-15 w-32 items-center object-contain ">
        <img
              src={Logo}
              alt="Logo.svg"
              style={{ width: '100%', height: '100%',  }}
            />
          
        </div>
        <ul className=" flex-row gap-x-10  xl:flex lg:flex md:hidden hidden nav-links items-center font-Outfit text-[15px] font-medium text-black">
          <li>
           <a
              href="/"
              className=" text-gray-400 hover:text-primary nav-font group" 
            >
              Home
              <div className="h-[3px] w-0 bg-primary group-hover:w-full transition-all duration-500"></div>
            </a>
            
          </li>
          <li>
           <a
              href="/about-us"
              className=" text-gray-400 hover:text-primary nav-font group" 
            >
              About us
              <div className="h-[3px] w-0 bg-primary group-hover:w-full transition-all duration-500"></div>
            </a>
            
          </li>
          <li>
            <Link to={"/products"}>
            <a
              
              className="font-semibold text-gray-400 hover:text-primary nav-font group"
            >
              Products
              <div className="h-[3px] w-0 bg-primary group-hover:w-full transition-all duration-500"></div>
            </a>
            </Link>
           
          </li>
          <li>
            <Link to={"/download-app"}>
            <a
              
              className="font-semibold text-gray-400 hover:text-primary nav-font group"
            >
              Download App
              <div className="h-[3px] w-0 bg-primary group-hover:w-full transition-all duration-500"></div>
            </a>
            </Link>
           
          </li>
          <li>
          <Link to={"/terms-and-conditions"}>
            <a
              
              className="font-semibold text-gray-400 hover:text-primary nav-font group"
            >
              Terms and Conditions
              <div className="h-[3px] w-0 bg-primary group-hover:w-full transition-all duration-500"></div>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/Privacy-policy"}>
            <a
              
              className="font-semibold text-gray-400 hover:text-primary nav-font group"
            >
              Privacy Policy
              <div className="h-[3px] w-0 bg-primary group-hover:w-full transition-all duration-500"></div>
            </a>
            </Link>
          </li>
         
        </ul>
            <div className=" flex  justify-end items-center gap-5 ">
         <div
            className="px-4 py-1 rounded-full  gap-x-2 items-center justify-center xl:flex lg:flex md:hidden hidden search-container bg-[#f0f2f5] dark:bg-[#242424]"
            
          >
            <SearchIcon style={{ color: "#696b6f" }} />
            <input
              type="text"
              placeholder="Search"
              className="bg-[transparent] outline-none placeholder:text-xs text-xs "
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
    
                <>
                <button onClick={toggleDrawer(true)}>
                <MenuIcon className="text-black dark:text-white"  /></button>
                <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
                  <Box sx={{ width:screenWidth, height: "100%", position: "relative" }} className="py-5 bg-white dark:bg-[#1a1a1a]">
                    <button
                      className="absolute right-10 cursor-pointer"
                      onClick={toggleDrawer(false)}
                    >
                      <span style={{ color: "red" }}>
                        Close <CloseIcon />
                      </span>
                    </button>
                    <div className="w-full h-full  grid place-items-center">
                    <ul className="flex flex-col gap-y-10 text-[12px]  ">
                    <li>
           <a
              href="#"
              className=" text-gray-400 hover:text-primary nav-font group" 
            >
              Home
              <div className="h-[3px] w-0 bg-primary group-hover:w-full transition-all duration-500"></div>
            </a>
            
          </li>
          <li>
            <Link to={"/products"}>
            <a
              
              className="font-semibold text-gray-400 hover:text-primary nav-font group"
            >
              Products
              <div className="h-[3px] w-0 bg-primary group-hover:w-full transition-all duration-500"></div>
            </a>
            </Link>
           
          </li>
          <li>
          <Link to={"/terms-and-conditions"}>
            <a
              
              className="font-semibold text-gray-400 hover:text-primary nav-font group"
            >
              Terms and Conditions
              <div className="h-[3px] w-0 bg-primary group-hover:w-full transition-all duration-500"></div>
            </a>
            </Link>
          </li>
          <li>
          <Link to={"/Privacy-policy"}>
            <a
              
              className="font-semibold text-gray-400 hover:text-primary nav-font group"
            >
              Privacy Policy
              <div className="h-[3px] w-0 bg-primary group-hover:w-full transition-all duration-500"></div>
            </a>
            </Link>
          </li>
            </ul>
              </div>
                  </Box>
                </Drawer>
              

              </>
          
              

          
         
        </div>

      </div>



    </header> */}
    <nav className={`${isScrolled ? 'z-50 fixed w-full top-0 animate-slideindown  shadow-none bg-white':'relative bg-white/80'} top-0 w-full z-50 shadow-md min-h-[10vh] `}>
      <div className="container  mx-auto px-4 lg:px-16 py-2 flex justify-between items-center w-full h-full">
      <div className="h-[65px] w-[120px] items-center ">
       <a href="/">
       <img
              src={Logo}
              alt="Logo.svg"
              style={{ width: '100%', height: '100%', objectFit:'contain' }}
            />
       </a>
          
        </div>
        {/* <ul className={`xl:flex-row xl:flex xl:relative xl:h-full xl:items-center  xl:w-fit xl:right-0 xl:gap-x-10 lg:flex-row lg:flex lg:relative lg:h-full lg:top-0 lg:w-fit lg:right-0 lg:gap-x-10  h-[90vh] absolute top-[10%] w-1/2 xl:justify-center lg:justify-center justify-between  z-20 flex-col   items-center font-Outfit text-[15px] font-medium  text-black  ${isOpen ? 'flex right-0 ':'hidden -right-[50%]'} `} > */}
        <ul className={`xl:flex xl:flex-row xl:top-0 xl:relative xl:items-center xl:w-fit xl:right-0 lg:flex-row  lg:flex lg:relative lg:top-0  xl:space-x-4 lg:space-x-4 font-DM-Sans text-[14px] font-semibold absolute top-[100%]  justify-between  flex-col right-0 items-center w-[75%]   ${isOpen ? 'flex right-0 transition-all duration-300 ease-in-out h-[90vh] py-5 w-1/2 bg-white justify-around shadow-xl':'hidden -right[150px]'}`}>
          {
            navList.map((item)=>(
              <li key={item.name}>
                {
                  item.dropdown ?(
                    <button className="flex items-center justify-center gap-1" onMouseEnter={toggleDropdown} >      <a
                    href={item.path}
                    className=" text-gray-400 hover:text-primary font-DM-Sans group" 
                  >
                    {item.name}
                    <div className="h-[3px] w-0 bg-primary group-hover:w-full transition-all duration-500"></div>
                  </a> {isHoverDropDown ? <ChevronUpIcon className="h-5 w-5 fill-gray-400 stroke-2"/>:<ChevronDownIcon className="h-5 w-5 fill-gray-400 stroke-2"/>}</button>
                  ):(
                    <a
                    href={item.path}
                    className=" text-gray-400 hover:text-primary font-DM-Sans group" 
                  >
                    {item.name}
                    <div className="h-[3px] w-0 bg-primary group-hover:w-full transition-all duration-500"></div>
                  </a>
                  )
                }
                {/* {
                  item.dropdown && isHoverDropDown && (
                    <ul className="absolute bg-white rounded-md w-[150px] shadow-md mt-2 py-2 px-4 flex flex-col gap-3 justify-center items-center" onMouseLeave={()=>setIsHoverDropDown(false)}>
                      {
                        item.items.map((subItem)=>(
                          <li>
                                 <a
                    href={subItem.path}
                    className=" text-gray-400 hover:text-primary font-DM-Sans group" 
                  >
                    {subItem.name}
                    <div className="h-[3px] w-0 bg-primary group-hover:w-full transition-all duration-500"></div>
                  </a>
                          </li>
                        ))
                      }

                    </ul>
                  )
                } */}
              </li>
            ))
          }

<a href='/contact'className="flex-col relative items-center justify-center group  flex bg-primary text-white font-semibold px-5 z-10 my-5 h-[30px] font-DM-Sans rounded-lg text-xs xl:text-[15px]  "  >
          <div className="absolute inset-0 w-0 group-hover:w-full bg-white/20 transition-all rounded-lg duration-300 ease-in-out z-0"></div>
          <span className="z-10">Contact us</span>
        </a>

        </ul>
        <button className="flex-col items-center justify-center  flex xl:hidden lg:hidden " onClick={()=>setIsOpen(!isOpen)} >
          {
            isOpen ? <XMarkIcon className={`h-6 w-6 fill-red-600 ${isOpen ? 'fill-black':''}`}/> : <Bars3BottomLeftIcon className={`h-6 w-6 fill-primary ${isOpen ? 'fill-black':''}`}/>
          }
          
        </button>


      </div>

    </nav>

  



</>
  );
};



export default Header;
