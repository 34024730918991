import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import BannerSection from '../../Components/Banner'
import { BoltIcon, EnvelopeIcon, MapIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/solid'
import {motion} from 'framer-motion'

const ContactUs = () => {
    const[errors,setErrors]=useState('')
  return (
    <>
            <Helmet>
        <title>Contact us</title>
        </Helmet>
        <BannerSection pagetitle={"Contact Us"}/>
        <section className='h-full'>

            <div className='  flex flex-col items-center justify-center gap-10'>
                <div className='lg:w-3/4 w-full  h-full flex flex-wrap items-center justify-center  md:justify-evenly lg:justify-evenly '>
                 
                <motion.div initial={{opacity:0,y:-100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{ease:'easeInOut',duration:3}} className='h-[200px]  w-[300px] relative flex flex-col items-center justify-center gap-2  shadow-xl rounded-tr-2xl rounded-bl-2xl px-5'>
  <div className='h-[70px] w-[70px] relative flex items-center justify-center'>
    <div className='h-full w-full border-2 border-dashed  border-primary rounded-full animate-spin360 absolute inset-0'></div>
    <PhoneIcon className='h-8 w-8 fill-primary'/>
  </div>
  <h3 className='text-center font-bold font-Jakarta'>Contact Us</h3>

  <div className='flex flex-col space-y-1 font-DM-Sans font-medium'>
    <a href="tel:+91962993939">+91-9962993939</a>
    <a href="tel:+91962993939">+91-9962993939</a>
  </div>
</motion.div>
                <motion.div initial={{opacity:0,y:-100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{ease:'easeInOut',duration:3}} className='h-[200px]  w-[300px] relative flex flex-col items-center justify-center gap-2  shadow-xl rounded-tr-2xl rounded-bl-2xl px-5'>
  <div className='h-[70px] w-[70px] relative flex items-center justify-center'>
    <div className='h-full w-full border-2 border-dashed  border-primary rounded-full animate-spin360 absolute inset-0'></div>
    <MapPinIcon className='h-8 w-8 fill-primary'/>
  </div>
  <h3 className='text-center font-bold font-Jakarta'>Address</h3>

  <div className='flex flex-col space-y-1 font-DM-Sans font-medium text-center'>
    <a>190-192, unit-19, Second Floor, Hameed Complex. Anna Salai, Chennai -600006.
</a>
   
  </div>
</motion.div>
                <motion.div initial={{opacity:0,y:-100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{ease:'easeInOut',duration:3}} className='h-[200px]  w-[300px] relative flex flex-col items-center justify-center gap-2  shadow-xl rounded-tr-2xl rounded-bl-2xl px-5'>
  <div className='h-[70px] w-[70px] relative flex items-center justify-center'>
    <div className='h-full w-full border-2 border-dashed  border-primary rounded-full animate-spin360 absolute inset-0'></div>
    <EnvelopeIcon className='h-8 w-8 fill-primary'/>
  </div>
  <h3 className='text-center font-bold font-Jakarta'>Mail</h3>

  <div className='flex flex-col space-y-1 font-DM-Sans font-medium'>
    <a href="mailto:milin@india1charger.com">milin@india1charger.com</a>
   
  </div>
</motion.div>
               
               
               
                </div>

                <div className=' my-10 flex items-center justify-center w-full '>
                    <div className='bg-white border h-full xl:w-3/4 w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 items-center justify-center p-10'>
                    <div className='w-full h-full flex flex-col gap-3'>
                    <div className="flex flex-row gap-4 items-center">
          <div className="w-8 h-2 border-primary border rounded-full ">
            <div className="w-full h-full bg-primary animate-progressHorizontal"></div>
          </div>
          <motion.span initial={{opacity:0}} whileInView={{opacity:1}} viewport={{once:false}} transition={{duration:0.8,ease:'easeInOut'}} className="text-[15px] font-DM-Sans uppercase font-semibold">
            Have Any Questions?
          </motion.span>

          <BoltIcon className="h-5 fill-primary" />
        </div>
        <h2 className='text-3xl font-Jakarta font-bold '>Drop Us a Line</h2>
                        <form action="" className='w-full h-full flex flex-col gap-5 justify-between'>
                            <div>
                                <input type="text" placeholder='Name' className='w-full border-b outline-none outline focus:border-b-2 focus:border-primary py-3' id='name' />
                                {errors.name && <span className='text-[10px] text-red-700 font-semibold'>{errors.name}</span>}
                            </div>
                            <div>
                                <input type="email" placeholder='Email' className='w-full border-b outline-none outline focus:border-b-2 focus:border-primary py-3' id='email' />
                                {errors.email && <span className='text-[10px] text-red-700 font-semibold'>{errors.email}</span>}
                            </div>
                            <div>
                                <input type="number" placeholder='Phone' className='w-full border-b outline-none outline focus:border-b-2 focus:border-primary py-3' id='number' />
                                {errors.phone && <span className='text-[10px] text-red-700 font-semibold'>{errors.phone}</span>}
                            </div>
                            <div>
                                {/* <input type="number" placeholder='Phone' className='w-full border-b outline-none outline focus:border-b-2 focus:border-primary py-3' id='number' /> */}
                                <textarea placeholder='Message' rows={4} className='w-full border-b outline-none focus:border-primary' id='message'/>
                                {errors.message && <span className='text-[10px] text-red-700 font-semibold'>{errors.message}</span>}
                            </div>
                            <button className='bg-primary relative h-[35px] font-DM-Sans w-1/3  group rounded-lg font-bold text-white'>
                            <div className='absolute inset-0 bg-white/20 w-0 group-hover:w-full transition-all ease-in-out duration-300 h-full rounded-lg'></div>
                            <span>Send</span>
                            </button>
                        </form>
                    </div>

            
                    <div className=''>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.090228826034!2d80.15053947454652!3d12.966077915000398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525e379a5b12df%3A0x29361f7a8979b13c!2sNilaithal!5e0!3m2!1sen!2sin!4v1721734435840!5m2!1sen!2sin"  style={{border:0}} className='w-full lg:w-[500px] xl:w-[500px] h-[500px]' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                    </div>
                </div>

                </div>
            </div>
        </section>
    </>
  )
}

export default ContactUs