import React, { useState } from 'react'
import p1 from '../../assets/Images/AC1.png'
import { LockClosedIcon, TrashIcon } from '@heroicons/react/24/solid'
import './style.css'
import {motion, useScroll}from 'framer-motion'
import Phonepe from '../../assets/Images/phonepe.svg'
import Master from '../../assets/Images/mastercard.svg'
import Visa from '../../assets/Images/visa.svg'
import Gpay from '../../assets/Images/google-pay.svg'
// import Rupay from '../../assets/Images/rupay.svg'

const CheckoutScreen = () => {
    const array = [1,2,3,4,5,6]

    
function isNumberKey(evt) {
  var charCode = (evt.which) ? evt.which : evt.keyCodeif (charCode > 31 && (charCode < 48 || charCode > 57))
    return false;
  return true;
}


const [loading,setloading]=useState(true)

const Cards = [
    {
        id:1,
        img:Master,
    },
    {
        id:2,
        img:Visa,
    },
    // {
    //     id:3,
    //     img:Rupay,
    // },
    {
        id:3,
        img:Gpay,
    },
]
 
  return (
    <>
    <section className='min-h-[90vh] flex flex-col items-center bg-[#d8d9dd]'>
        <div className='grid lg:grid-cols-2 h-full w-11/12 my-5 gap-5'>
            <div className='bg-white h-full rounded-xl py-5 px-2'>
                <div className='flex flex-col gap-4'>
                <h2 className='font-bold font-Jakarta text-2xl'>Shopping Cart</h2>
                <hr className='font-bold w-full  border'/>
                </div>
                <div className='h-[500px] overflow-y-scroll custom-scrollbar'>
                {
                    array.map((item)=>(
                        <motion.div initial={{opacity:0,scale:0.5}} whileInView={{opacity:1,scale:1}} viewport={{once:true}} transition={{ease:'easeInOut',duration:0.8}} className=' h-[120px] flex my-5 flex-row mx-2 rounded-lg  gap-2 shadow-lg p-2'>
                            <div className='h-[100px] w-[150px] bg-[#d8d9dd] rounded-xl'>
                                <img src={p1} alt="" className='h-full w-full object-contain' />
                            </div>
                            <div className='flex flex-row justify-between w-full bg-white/20'>
                                <div className='flex-col flex justify-between h-full'>
                                    <h5 className='text-[12px] font-Jakarta font-semibold'>AC 3.3Kw Charger</h5>
                                    <p className='text-xs font-DM-Sans'>For Two wheelers and Four Wheelers</p>
                                    <p className='text-xs font-DM-Sans'>ID: 244310</p>

                                </div>
                                <div className='flex flex-row gap-2'>
                                <div className='flex-col flex justify-between h-full'>
                                    <h5 className='text-[12px] font-Jakarta font-semibold'>₹ 500</h5>
                                    <p className='text-xs font-DM-Sans'>Volatage: <span className='text-primary font-semibold'>15kw</span></p>
                                    <p className='text-xs font-DM-Sans'>QTY: <span className='text-primary font-semibold'>0{item}</span></p>

                                </div>
                                <div className='w-fit h-fit cursor-pointer'>
                                    <TrashIcon className='h-6 w-6 fill-[#d8d9dd] hover:fill-red-700'/>
                                </div>
                                </div>

                            </div>

                        </motion.div>
                    ))
                }

                </div>
               
                            
            </div>
            <div className='flex flex-col gap-5'>
                <div className='h-fit bg-white rounded-xl py-5 px-3 flex-col flex gap-4'>
                <div className='flex flex-col gap-4'>
                <h2 className='font-bold font-Jakarta text-2xl'>Delivery Info</h2>
                <hr className='font-bold w-full  border'/>
                </div>
                
                    <form className='font-DM-Sans flex flex-col gap-4'>
                        <div className='flex flex-wrap items-center w-full justify-center gap-2 '>
                            <div className='flex flex-col gap-4 text-[14px] lg:w-[49%] md:w-[49%] w-full'>
                                <label htmlFor="firstname" className='font-semibold text-blue-gray-400 '>
                                    First Name *
                                </label>
                                <input type="text" id='firstname' name='firstname' className={`outline-none border-2 rounded-lg py-2 px-5 focus:border-primary `}/>
                            </div>
                            <div className='flex flex-col gap-4 text-[14px] lg:w-[49%] md:w-[49%] w-full'>
                                <label htmlFor="lastname" className='font-semibold text-blue-gray-400 '>
                                    Last Name *
                                </label>
                                <input type="text" id='lastname' name='lastname' className={`outline-none border-2 rounded-lg py-2 px-5 focus:border-primary `}/>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4 text-[14px] w-full'>
                                <label htmlFor="mail" className='font-semibold text-blue-gray-400 '>
                                    Email *
                                </label>
                                <input type="mail" id='mail' name='mail' className={`outline-none border-2 rounded-lg py-2 px-5 focus:border-primary `}/>
                            </div>
                        <div className='flex flex-col gap-4 text-[14px] w-full'>
                                <label htmlFor="address" className='font-semibold text-blue-gray-400 '>
                                    Address *
                                </label>
                                <input type="text" id='address' name='address' className={`outline-none border-2 rounded-lg py-2 px-5 focus:border-primary `}/>
                            </div>
                            <div className='flex flex-wrap items-center w-full justify-center gap-2 '>
                            <div className='flex flex-col gap-4 text-[14px] lg:w-[49%] md:w-[49%] w-full'>
                                <label htmlFor="city" className='font-semibold text-blue-gray-400 '>
                                    City *
                                </label>
                                <input type="text" id='city' name='city' className={`outline-none border-2 rounded-lg py-2 px-5 focus:border-primary `}/>
                            </div>
                            <div className='flex flex-col gap-4 text-[14px] lg:w-[49%] md:w-[49%] w-full'>
                                <label htmlFor="state" className='font-semibold text-blue-gray-400 '>
                                    State *
                                </label>
                                <input type="text" id='state' name='state' className={`outline-none border-2 rounded-lg py-2 px-5 focus:border-primary `}/>
                            </div>
                        </div>
                            <div className='flex flex-wrap items-center w-full justify-center gap-2 '>
                            <div className='flex flex-col gap-4 text-[14px] lg:w-[49%] md:w-[49%] w-full'>
                                <label htmlFor="pincode" className='font-semibold text-blue-gray-400 '>
                                    Pincode *
                                </label>
                                <input  type="number" minlength="6" maxlength="6" id='pincode' name='pincode' className={`outline-none border-2 rounded-lg py-2 px-5 focus:border-primary `}onKeyDown={(event)=>isNumberKey(event)}/>
                            </div>
                            <div className='flex flex-col gap-4 text-[14px] lg:w-[49%] md:w-[49%] w-full'>
                                <label htmlFor="phone" className='font-semibold text-blue-gray-400 '>
                                    Phone *
                                </label>
                                <input type="number" max={10} name='phone' id='phone' className={`outline-none border-2 rounded-lg py-2 px-5 focus:border-primary `}/>
                            </div>
                        </div>
                            
                    </form>
                </div>
                <div className=' bg-white rounded-xl py-5 px-3 relative h-fit'>
                    {/* <div className='absolute bg-black/60 h-full z-10 w-full inset-0 rounded-xl flex flex-col items-center justify-center'>
                    <LockClosedIcon className='h-16 w-16 fill-white'/>
                    </div> */}

                    <>
                    <div className='flex flex-col gap-4'>
                <h2 className='font-bold font-Jakarta text-2xl'>Payment</h2>
                <hr className='font-bold w-full  border'/>
                </div>
                <div className={`my-5 mx-5 border-l-8 ${loading ? 'border-gray-300':'border-purple-800'} rounded-xl shadow-xl shadow-gray-300 `}>
                    <div className='flex flex-row h-full px-5 py-5 '>
                        <div className='h-full  w-full'>
                        <h2 className={`${loading ?'text-gray-300': 'text-purple-800'} text-2xl font-Montserrat font-bold`}>PhonePe</h2>
                        <p className={`w-full font-DM-Sans font-semibold ${loading ?'text-gray-300': 'text-black'}`}>
                                You will be redirected to our secure payment checkout.             
                        </p>

                        </div>
                        <div className=' h-full w-full flex flex-col justify-center'>
                            <div className='h-[70px] flex flex-row items-center justify-end'>
                                <img src={Phonepe} alt="" className={`${loading ? 'grayscale':'grayscale-0'} object-contain`} width={"70%"} />  
                            </div>
                            <div className='flex items-center justify-end gap-3 '>
                                {
                                    Cards.map((obj,index)=>(
                                        <div className='h-[30px] w-[30px] border-2 rounded-md'>
                                            <img src={obj.img} alt="" className={`h-full w-full object-contain rounded-md filter ${loading ? 'grayscale':'grayscale-0'}`} />
                                        </div>
                                    ))
                                }
                                
                            </div>

                        </div>
                       
                    </div>

                    <button className={`w-full relative rounded-bl rounded-br-lg h-[40px] group font-Jakarta font-bold text-white hover:bg-black ${loading ? 'bg-gray-400':'bg-purple-800'}`} disabled={loading}>
                        <div className='absolute h-full w-0 inset-0 bg-white/20 rounded-bl-lg rounded-br-lg group-hover:w-full transition-all duration-300 ease-in-out'></div>
                        <span>{loading?"Fill Billing Details to Proceed":">You will be charged ₹ 5,000"}</span>
                    </button>
                </div>
                    </>

                </div>

            </div>

        </div>

    </section>
    </>
  )
}

export default CheckoutScreen