import React from 'react'
import { Helmet } from 'react-helmet'
import BG from '../../assets/Images/slider2.jpg'
import {motion} from 'framer-motion'
import './style.css'

const PageNotFound = () => {
  return (
    <>
    <Helmet>
        <title>Page Not Found</title>
    </Helmet>
    <section className='min-h-[90vh] flex flex-col gap-2 items-center justify-center'>
        <motion.h2 initial={{opacity:0,scale:0}} whileInView={{opacity:1,scale:1}} viewport={{once:true}} transition={{ease:'easeInOut',duration:0.8}} className='xl:text-[200px] text-[100px] bg-no-repe bg-text font-bold font-Jakarta'>Oops!</motion.h2>
        <h4 className='font-DM-Sans uppercase text-xl xl:text-2xl lg:text-2xl md:text-2xl font-bold text-center animate-fadein'>404 - Page Not Found</h4>
        <p className='text-center w-full lg:w-1/2 md:w-1/2 font-semibold font-DM-Sans animate-fadein'>The page you are looking for might have been removed
        had its name changed or is temporarily unavailable.</p>
        <a href="/">
        <button className='h-[35px] relative bg-primary px-5 rounded-lg font-DM-Sans group font-semibold text-white animate-fadein '>
            <div className='absolute inset-0 bg-white/20 w-0 rounded-lg group-hover:w-full transition-all duration-200 ease-linear'></div>
            <span>Go to Homepage</span>
        </button>
        </a>


    </section>
    </>
  )
}

export default PageNotFound