import React, { useEffect, useState } from 'react'
import ChargerIMg1 from '../../assets/Images/AC1.png'
import ChargerIMg2 from '../../assets/Images/AC2.png'
import { Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import BannerSection from '../../Components/Banner'

const Products = () => {
  const hash = useLocation();
  useEffect(()=>{
    if(hash){
      const elementId = hash.hash.startsWith('#') ? hash.hash.substring(1) : hash;
      const element = document.getElementById(elementId)
      if(element){
        element.scrollIntoView({behavior:'smooth'})
      }
    }

  },[hash])
    const data =[
        {
          id: "ac1",
          name: "AC 3.3 kW charger with 3 to 5 universal sockets per one Smart Control Unit",
          voltage: "3.3 KW",
          price: 78000,
          currency: "INR",
          description: "A reliable AC charger for home use.",
          brand: "ChargerBrand",
          category: "AC",
          stock: 100,
          main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-5-176x300.png",
          images: [
            ChargerIMg1,
            ChargerIMg2,
            "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-4-198x300.png"
          ]
        },
        {
          id: "ac2",
          name: "AC 15 kW charger with Dual Type 2 connector (7.4kw each) / (IEC 62196)",
          voltage: "15KW",
          price: 92450,
          currency: "INR",
          description: "A powerful AC charger for international use.",
          brand: "PowerPlus",
          category: "AC",
          stock: 50,
          main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-8-134x300.png",
          images: [
            "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-8-134x300.png",
            ChargerIMg1,
            ChargerIMg2,
           
          ]
        },
        {
          id: "dc1",
          name: "30 kW DC (Fast) charger with single connector (CCS2)",
          voltage: "30KW",
          price: 57700,
          currency: "INR",
          description: "A compact DC charger for automotive use.",
          brand: "AutoCharge",
          category: "DC",
          stock: 75,
          main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-4-198x300.png",
          images: [
            ChargerIMg1,
            ChargerIMg2,
            "https://example.com/images/dc1_side.jpg",
            "https://example.com/images/dc1_back.jpg"
          ]
        },
        {
          id: "dc2",
          name: "60 kW DC (Fast) charger with single connector (CCS2)",
          voltage: "60KW",
          price: 85000,
          currency: "INR",
          description: "A high-power DC charger for trucks and heavy vehicles.",
          brand: "TruckPower",
          category: "DC",
          stock: 30,
          main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-4-198x300.png",
          images: [
            ChargerIMg1,
            ChargerIMg2,
            "https://example.com/images/dc2_front.jpg",
            "https://example.com/images/dc2_side.jpg",
            "https://example.com/images/dc2_back.jpg"
          ]
        },
        {
          id: "pc1",
          name: "AC 7.4 kW charger with Type 2 connector with (IEC 62196) and 3.3 universal socket",
          voltage: "7.4KW",
          price: 78750,
          currency: "INR",
          description: "A handy portable charger for mobile devices.",
          brand: "TravelCharge",
          category: "Portable",
          stock: 150,
          main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-9.png",
          images: [
            ChargerIMg1,
            ChargerIMg2,
            "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-9.png",
          ]
        },
        {
          id: "pc2",
          name: "AC 22 kW charger with Type 2 connector (IEC 62196)",
          voltage: "9V",
          price: 68250,
          currency: "INR",
          description: "A fast-charging portable charger for smartphones and tablets.",
          brand: "FastCharge",
          category: "Portable",
          stock: 100,
          main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-10.png",
          images: [
            ChargerIMg1,
            ChargerIMg2,
            "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-10.png"
          ]
        },
      ]
      const [hoveredImage,setHoveredImage]=useState(null);
  return (
    <>
        <Helmet>
        <title>Products</title>
        </Helmet>
        <BannerSection pagetitle={"Products"}/>
    <section className='h-1/2 w-full bg-[#f9f9f9] px-5 xl:px-32 md:px-16 py-10 xl:py-26 md:py-16 border-b-2' id='ac-chargers'>
    <div className='flex flex-col gap-5'>
    <h2 className='font-bold xl:text-[56px] text-4xl font-Jakarta'>
        AC Chargers
    </h2>
    <p className='text-[17px] text-gray-500 font-DM-Sans'>
    Welcome to the India1 Charger online store, your one-stop shop for all your charging needs. Explore our wide range of high-quality chargers designed to keep you powered up wherever you go. From home charging solutions to on-the-go options, we have everything you need to stay connected and charged up.
    </p>

    </div>

    <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 md:grid-cols-2  place-items-center gap-5 my-10'>
    {
        data.map((item,index)=>(
            // <Link to={`/products/${item.id}`} key={item.id} className='border-2 rounded-lg  group bg-[#f1f1f1] hover:border-primary h-full'>
            //     <div className=' flex flex-row items-center justify-center'>
            //     <div className="relative h-[200px] w-[300px] py-5 " onMouseEnter={()=>setHoveredImage(item.id)} onMouseLeave={()=>setHoveredImage(null)}>
            //       <img src={hoveredImage === item.id ? item.images[1] : item.main_image} alt={item.name} className=" h-full self-stretch flex-shrink-0 object-contain w-full transition transform duration-300 ease-in-out   group-hover:scale-110" />
            //     </div>
            //     </div>
                
            //     <div className='flex flex-col gap-5  px-5 py-2 bg-white rounded-b-lg h-[250px]'>
            //     <h4 className='font-bold text-xl  line-clamp-2 overflow-hidden text-wrap group-hover:text-primary font-Jakarta text-ellipsis'>{item.name}</h4>
            //     <h6 className='font-bold font-Montserrat'>Volatage: {item.voltage}</h6>
            //     <p className='font-semibold font-DM-Sans'>₹{item.price.toLocaleString()}</p>
            //     <div className='flex flex-row gap-5'>
            //         <button className='bg-primary text-white px-5 py-2 rounded-lg hover:bg-black font-DM-Sans'>Add to Cart</button>
            //         <button className='border px-5 rounded-lg hover:text-primary font-DM-Sans'>Buy Now</button>
            //     </div>

            //     </div>
                
            // </Link>
            <Link to={`/products/${item.id}`} key={item.id}>
            <div className='h-[180px] xl:w-[400px] lg:w-[420px] md:w-[300px] border border-primary rounded-md flex flex-row p-2'>
              <div className='w-[35%] h-full ' onMouseEnter={()=>setHoveredImage(item.id)} onMouseLeave={()=>setHoveredImage(null)}>
              <img src={hoveredImage === item.id ? item.images[1] : item.main_image} alt={item.name} className=" h-full self-stretch flex-shrink-0 object-contain w-full transition transform duration-300 ease-in-out   group-hover:scale-110" />
              </div>
              <div className='w-[65%] flex flex-col gap-5  justify-center '>
              <h4 className='font-bold text-[15px] line-clamp-2 xl:line-clamp-1  overflow-hidden text-wrap group-hover:text-primary font-Jakarta text-ellipsis'>{item.name}</h4>
              <h6 className='font-bold font-Montserrat text-[15px]'>Volatage: {item.voltage}</h6>
              <p className='font-semibold font-Montserrat text-xs'>₹{item.price.toLocaleString()}</p>
              <button className=' w-1/2 bg-primary text-white px-1 py-0 text-[12px] xl:text-[15px] lg:text-[15px] rounded-lg hover:bg-black font-DM-Sans'>Add to Cart</button>
              </div>

            </div>
            </Link>
        ))
    }


    </div>
   


    </section>
    <section className='h-1/2 w-full bg-[#f9f9f9] px-5 xl:px-32 md:px-16 py-10 xl:py-24 md:py-16 border-b-2' id='dc-chargers'>
    <div className='flex flex-col gap-5'>
    <h2 className='font-bold xl:text-[56px] text-4xl font-Jakarta'>
        DC Chargers
    </h2>
    <p className='text-[17px] text-gray-500 font-DM-Sans'>
    Welcome to the India1 Charger online store, your one-stop shop for all your charging needs. Explore our wide range of high-quality chargers designed to keep you powered up wherever you go. From home charging solutions to on-the-go options, we have everything you need to stay connected and charged up.
    </p>

    </div>

    <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 md:grid-cols-2  place-items-center gap-5 my-10'>
    {
        data.map((item,index)=>(
            // <Link to={`/products/${item.id}`} key={item.id} className='border-2 rounded-lg  group bg-[#f1f1f1] hover:border-primary h-full'>
            //     <div className=' flex flex-row items-center justify-center'>
            //     <div className="relative h-[200px] w-[300px] py-5 " onMouseEnter={()=>setHoveredImage(item.id)} onMouseLeave={()=>setHoveredImage(null)}>
            //       <img src={hoveredImage === item.id ? item.images[1] : item.main_image} alt={item.name} className=" h-full self-stretch flex-shrink-0 object-contain w-full transition transform duration-300 ease-in-out   group-hover:scale-110" />
            //     </div>
            //     </div>
                
            //     <div className='flex flex-col gap-5  px-5 py-2 bg-white rounded-b-lg h-[250px]'>
            //     <h4 className='font-bold text-xl  line-clamp-2 overflow-hidden text-wrap group-hover:text-primary font-Jakarta text-ellipsis'>{item.name}</h4>
            //     <h6 className='font-bold font-Montserrat'>Volatage: {item.voltage}</h6>
            //     <p className='font-semibold font-DM-Sans'>₹{item.price.toLocaleString()}</p>
            //     <div className='flex flex-row gap-5'>
            //         <button className='bg-primary text-white px-5 py-2 rounded-lg hover:bg-black font-DM-Sans'>Add to Cart</button>
            //         <button className='border px-5 rounded-lg hover:text-primary font-DM-Sans'>Buy Now</button>
            //     </div>

            //     </div>
                
            // </Link>
            <Link to={`/products/${item.id}`} key={item.id}>
            <div className='h-[180px] xl:w-[400px] lg:w-[420px] md:w-[300px] border border-primary rounded-md flex flex-row p-2'>
              <div className='w-[35%] h-full ' onMouseEnter={()=>setHoveredImage(item.id)} onMouseLeave={()=>setHoveredImage(null)}>
              <img src={hoveredImage === item.id ? item.images[1] : item.main_image} alt={item.name} className=" h-full self-stretch flex-shrink-0 object-contain w-full transition transform duration-300 ease-in-out   group-hover:scale-110" />
              </div>
              <div className='w-[65%] flex flex-col gap-5  justify-center '>
              <h4 className='font-bold text-[15px] line-clamp-2 xl:line-clamp-1  overflow-hidden text-wrap group-hover:text-primary font-Jakarta text-ellipsis'>{item.name}</h4>
              <h6 className='font-bold font-Montserrat text-[15px]'>Volatage: {item.voltage}</h6>
              <p className='font-semibold font-Montserrat text-xs'>₹{item.price.toLocaleString()}</p>
              <button className=' w-1/2 bg-primary text-white px-1 py-0 text-[12px] xl:text-[15px] lg:text-[15px] rounded-lg hover:bg-black font-DM-Sans'>Add to Cart</button>
              </div>

            </div>
            </Link>
        ))
    }


    </div>
   


    </section>
    </>
  )
}

export default Products