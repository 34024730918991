import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import ChargerIMg1 from '../../assets/Images/AC1.png'
import ChargerIMg2 from '../../assets/Images/AC2.png'
import { BoltIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid'
import { Helmet } from 'react-helmet'
import BannerSection from '../../Components/Banner'
import {motion} from 'framer-motion'

const ProductLandingPage = () => {

    const {productid}=useParams();

  const data =[
          {
            id: "ac1",
            name: "AC 3.3 kW charger with 3 to 5 universal sockets per one Smart Control Unit",
            voltage: "3.3 KW",
            price: 78000,
            currency: "INR",
            description: "A reliable AC charger for home use.",
            brand: "ChargerBrand",
            category: "AC",
            stock: 100,
            main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-5-176x300.png",
            images: [
              "ChargerIMg1",
              "ChargerIMg2",
              "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-4-198x300.png"
            ],
            technical_details: [
              {
                id: 1,
                name: "Number of Outputs",
                value: "1 or 2 (Optional)"
              },
              {
                id: 2,
                name: "Output Voltage",
                value: "190-420Vac"
              },
              {
                id: 3,
                name: "Display",
                value: "7″ TFT LCD (Optional)"
              },
              {
                id: 4,
                name: "Connectivity",
                value: "WiFi or GSM"
              },
              {
                id: 5,
                name: "Cable Length",
                value: "5 meters"
              },
              {
                id: 6,
                name: "Communication Protocol",
                value: "OCPP 1.6J & 2.0.1 compliant"
              }
            ],
            other_detail: [
              "IP55 water resistance makes reliable for both indoor and outdoor use.",
              "Our charger can handle temperature from -20 °C to 65 °C.",
              "Its Compact design takes up less space and makes it easy to install.",
              "Multi-Mode payment support.",
              "Our 24×7 customer support ensures that your charger performs at its optimum state."
            ]
          },
          {
            id: "ac2",
            name: "AC 15 kW charger with Dual Type 2 connector (7.4kw each) / (IEC 62196)",
            voltage: "15KW",
            price: 92450,
            currency: "INR",
            description: "A powerful AC charger for international use.",
            brand: "PowerPlus",
            category: "AC",
            stock: 50,
            main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-8-134x300.png",
            images: [
              "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-8-134x300.png",
              "ChargerIMg1",
              "ChargerIMg2"
            ],
            technical_details: [
              {
                id: 1,
                name: "Number of Outputs",
                value: "1 or 2 (Optional)"
              },
              {
                id: 2,
                name: "Output Voltage",
                value: "190-420Vac"
              },
              {
                id: 3,
                name: "Display",
                value: "7″ TFT LCD (Optional)"
              },
              {
                id: 4,
                name: "Connectivity",
                value: "WiFi or GSM"
              },
              {
                id: 5,
                name: "Cable Length",
                value: "5 meters"
              },
              {
                id: 6,
                name: "Communication Protocol",
                value: "OCPP 1.6J & 2.0.1 compliant"
              }
            ],
            other_detail: [
              "IP55 water resistance makes reliable for both indoor and outdoor use.",
              "Our charger can handle temperature from -20 °C to 65 °C.",
              "Its Compact design takes up less space and makes it easy to install.",
              "Multi-Mode payment support.",
              "Our 24×7 customer support ensures that your charger performs at its optimum state."
            ]
          },
          {
            id: "dc1",
            name: "30 kW DC (Fast) charger with single connector (CCS2)",
            voltage: "30KW",
            price: 57700,
            currency: "INR",
            description: "A compact DC charger for automotive use.",
            brand: "AutoCharge",
            category: "DC",
            stock: 75,
            main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-4-198x300.png",
            images: [
              "ChargerIMg1",
              "ChargerIMg2",
              "https://example.com/images/dc1_side.jpg",
              "https://example.com/images/dc1_back.jpg"
            ],
            technical_details: [
              {
                id: 1,
                name: "Number of Outputs",
                value: "1 or 2 (Optional)"
              },
              {
                id: 2,
                name: "Output Voltage",
                value: "190-420Vac"
              },
              {
                id: 3,
                name: "Display",
                value: "7″ TFT LCD (Optional)"
              },
              {
                id: 4,
                name: "Connectivity",
                value: "WiFi or GSM"
              },
              {
                id: 5,
                name: "Cable Length",
                value: "5 meters"
              },
              {
                id: 6,
                name: "Communication Protocol",
                value: "OCPP 1.6J & 2.0.1 compliant"
              }
            ],
            other_detail: [
              "IP55 water resistance makes reliable for both indoor and outdoor use.",
              "Our charger can handle temperature from -20 °C to 65 °C.",
              "Its Compact design takes up less space and makes it easy to install.",
              "Multi-Mode payment support.",
              "Our 24×7 customer support ensures that your charger performs at its optimum state."
            ]
          },
          {
            id: "dc2",
            name: "60 kW DC (Fast) charger with single connector (CCS2)",
            voltage: "60KW",
            price: 85000,
            currency: "INR",
            description: "A high-power DC charger for trucks and heavy vehicles.",
            brand: "TruckPower",
            category: "DC",
            stock: 30,
            main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-4-198x300.png",
            images: [
              "ChargerIMg1",
              "ChargerIMg2",
              "https://example.com/images/dc2_front.jpg",
              "https://example.com/images/dc2_side.jpg",
              "https://example.com/images/dc2_back.jpg"
            ],
            technical_details: [
              {
                id: 1,
                name: "Number of Outputs",
                value: "1 or 2 (Optional)"
              },
              {
                id: 2,
                name: "Output Voltage",
                value: "190-420Vac"
              },
              {
                id: 3,
                name: "Display",
                value: "7″ TFT LCD (Optional)"
              },
              {
                id: 4,
                name: "Connectivity",
                value: "WiFi or GSM"
              },
              {
                id: 5,
                name: "Cable Length",
                value: "5 meters"
              },
              {
                id: 6,
                name: "Communication Protocol",
                value: "OCPP 1.6J & 2.0.1 compliant"
              }
            ],
            other_detail: [
              "IP55 water resistance makes reliable for both indoor and outdoor use.",
              "Our charger can handle temperature from -20 °C to 65 °C.",
              "Its Compact design takes up less space and makes it easy to install.",
              "Multi-Mode payment support.",
              "Our 24×7 customer support ensures that your charger performs at its optimum state."
            ]
          },
          {
            id: "pc1",
            name: "AC 7.4 kW charger with Type 2 connector with (IEC 62196) and 3.3 universal socket",
            voltage: "7.4KW",
            price: 78750,
            currency: "INR",
            description: "A handy portable charger for mobile devices.",
            brand: "TravelCharge",
            category: "Portable",
            stock: 150,
            main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-9.png",
            images: [
              "ChargerIMg1",
              "ChargerIMg2",
              "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-9.png"
            ],
            technical_details: [
              {
                id: 1,
                name: "Number of Outputs",
                value: "1 or 2 (Optional)"
              },
              {
                id: 2,
                name: "Output Voltage",
                value: "190-420Vac"
              },
              {
                id: 3,
                name: "Display",
                value: "7″ TFT LCD (Optional)"
              },
              {
                id: 4,
                name: "Connectivity",
                value: "WiFi or GSM"
              },
              {
                id: 5,
                name: "Cable Length",
                value: "5 meters"
              },
              {
                id: 6,
                name: "Communication Protocol",
                value: "OCPP 1.6J & 2.0.1 compliant"
              }
            ],
            other_detail: [
              "IP55 water resistance makes reliable for both indoor and outdoor use.",
              "Our charger can handle temperature from -20 °C to 65 °C.",
              "Its Compact design takes up less space and makes it easy to install.",
              "Multi-Mode payment support.",
              "Our 24×7 customer support ensures that your charger performs at its optimum state."
            ]
          },
          {
            id: "pc2",
            name: "AC 22 kW charger with Type 2 connector (IEC 62196)",
            voltage: "9V",
            price: 68250,
            currency: "INR",
            description: "A fast-charging portable charger for smartphones and tablets.",
            brand: "FastCharge",
            category: "Portable",
            stock: 100,
            main_image: "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-10.png",
            images: [
              "ChargerIMg1",
              "ChargerIMg2",
              "https://ev.ideassion.in/wp-content/uploads/2024/04/image-removebg-preview-10.png"
            ],
            technical_details: [
              {
                id: 1,
                name: "Number of Outputs",
                value: "1 or 2 (Optional)"
              },
              {
                id: 2,
                name: "Output Voltage",
                value: "190-420Vac"
              },
              {
                id: 3,
                name: "Display",
                value: "7″ TFT LCD (Optional)"
              },
              {
                id: 4,
                name: "Connectivity",
                value: "WiFi or GSM"
              },
              {
                id: 5,
                name: "Cable Length",
                value: "5 meters"
              },
              {
                id: 6,
                name: "Communication Protocol",
                value: "OCPP 1.6J & 2.0.1 compliant"
              }
            ],
            other_detail: [
              "IP55 water resistance makes reliable for both indoor and outdoor use.",
              "Our charger can handle temperature from -20 °C to 65 °C.",
              "Its Compact design takes up less space and makes it easy to install.",
              "Multi-Mode payment support.",
              "Our 24×7 customer support ensures that your charger performs at its optimum state."
            ]
          }
        ]
    
      

      const ProductData = data.find(item=>item.id === productid)

      const [quantity,setQuantity]=useState(1)
  return (
    <>
            <Helmet>
        <title>{ProductData.name}</title>
        </Helmet>
    <section className='min-h-screen grid lg:grid-cols-2 md:grid-cols-1 items-center justify-center px-5 lg:px-10  py-28'>
        <div className='flex flex-col gap-5  justify-center'>
            <motion.h2 initial={{opacity:0,y:-100}} whileInView={{opacity:1,y:0}} viewport={{once:false}} transition={{ease:'easeInOut'}} className='font-bold lg:text-[42px] md:text-3xl text-xl lg:leading-[1.5em]  font-Jakarta '>
                {ProductData.name}

            </motion.h2>
            <p className='text-[15px] font-medium text-blue-gray-300 font-DM-Sans'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta obcaecati exercitationem atque libero delectus temporibus, commodi recusandae natus quam ea.</p>

            <h4 className='font-bold font-DM-Sans text-xxl'>Voltage {ProductData.voltage}</h4>

            <h5 className='font-bold text-3xl font-Montserrat'>₹{ProductData.price.toLocaleString()}</h5>

            <div className='flex flex-row items-center gap-5'>
              <div className='flex flex-row gap-4 items-center justify-start'>
                <button onClick={()=>setQuantity((prev)=>prev -1)} disabled={quantity < 2 ? true : false}><MinusCircleIcon className={`h-10 w-10 ${quantity < 2 ?'fill-gray-400':'fill-primary'} cursor-pointer`}/></button>
                <p className='font-DM-Sans font-medium'>{quantity}</p>
                <button  onClick={()=>setQuantity((prev)=>prev+1)}><PlusCircleIcon className='h-10 w-10 fill-primary cursor-pointer'/></button>
              </div>
              <a href="/checkout"><button className="flex-col relative items-center justify-center group py-2  flex bg-primary text-white font-semibold px-5 z-10 my-5 h-[40px] font-DM-Sans rounded-lg text-xs xl:text-[15px]  "  >
          <div className="absolute inset-0 w-0 group-hover:w-full bg-white/20 transition-all rounded-lg duration-300 ease-in-out z-0"></div>
          <span className="z-10">Buy Now</span>
            </button>
            </a>
              </div>


           

        </div>
        <div className='flex flex-col items-center justify-center'>
            <div className='lg:h-[500px] md:h-[400px] h-[200px]'>
                <img src={ProductData.main_image} alt="" className='h-full w-full object-contain' />
            </div>

        </div>

    </section>
    <section className='h-fit bg-[#f3f3f3] py-10 flex flex-col items-center gap-5'>
        <h5 className='font-Jakarta text-xl font-semibold text-center text-blue-gray-300'>Key Features</h5>
        <p className='text-center w-full lg:w-1/2 md:w-1/2'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facilis dolore quas veniam consectetur! Molestias voluptas consequatur eaque, beatae ipsum dolore.</p>

        <div className='grid  lg:grid-cols-3 md:grid-cols-2 gap-10'>
            {
                ProductData.technical_details.map((obj,index)=>(
                    <motion.div initial={{opacity:0,scale:0}} whileInView={{opacity:1,scale:1}} viewport={{once:false}} transition={{ease:'easeInOut'}} className='bg-white  flex flex-col gap-5 items-center rounded-lg shadow-lg hover:shadow-primary animate-fadeinup px-5 h-44 justify-center'>
                        <div>
                            <BoltIcon className='h-6 w-6 fill-primary'/>
                        </div>
                        <h3 className='font-Jakarta font-bold text-xl text-center'>{obj.name}</h3>
                        <p className='font-DM-Sans font-medium text-blue-gray-400  text-[14px]'>
                            {obj.value}
                        </p>

                    </motion.div>
                )
                )
            }
        </div>

    </section>
    <section className=' py-10 flex flex-col items-center gap-10'>
    <h5 className='font-Jakarta text-xl font-semibold text-center text-blue-gray-300'>Other Details</h5>
    <ul className='list list-disc flex flex-col gap-5 font-DM-Sans text-[15px] '>
        {
            ProductData.other_detail.map((obj,index)=>(
                <li className='list-disc'>{obj}</li>
            ))
        }
    </ul>
    </section>

    </>
  )
}

export default ProductLandingPage