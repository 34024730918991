import React from 'react'
import { Helmet } from 'react-helmet'
import BannerSection from '../../Components/Banner'

const PrivacyPolicy = () => {
  return (
    <>
            <Helmet>
        <title>Privacy Policy</title>
        </Helmet>
        <BannerSection pagetitle={"Privacy Policy"}/>
    <section className='flex flex-col gap-10 h-full xl:px-20 px-5'>

   

        <div className=' flex flex-col gap-5'>
            <h5 className='font-Jakarta text-3xl font-bold'>About</h5>
            <p className='font-DM-Sans text-[15px]'>
            Protection of data is a matter of trust and confidence. (CIN: U35105TN2024PTC168120) a company and its product India1 Charger incorporated under the Companies Act, 2013, having its registered office at No 190 – 192, Hameed Complex Anna Salai, Chennai 600006 (“Company” / “We” / “Us”) value the privacy of the user (“User”/ “You”/ “Your”) and understand that protection of User data is very important for the business of the Company. The Company agrees to use information provided only for the purposes set out in this Privacy Policy. This document is an electronic record in terms of the Information Technology Act, 2000, and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures. This Privacy Policy applies only to https://cms.India1 Charger.com/, https://India1 Charger.com/,  to the exclusion of any third party websites and to the India1 Charger mobile application (“Mobile Application”). The Website and the Mobile Application shall be collectively referred to as “Platform”. By accessing and using the services offered through the Platform, you hereby consent to the usage of the information including personally identifiable information in line with the terms set out in this Privacy Policy. This Privacy Policy shall be subject to change and any changes shall be notified on the Platform. Continued use of the Platform post such intimation, shall be deemed consent by the User of such changes.


            </p>
        </div>
        <div className=' flex flex-col gap-5'>
            <h5 className='font-Jakarta text-3xl font-bold'>Platform Visitors
            </h5>
            <p className='font-DM-Sans text-[15px]'>No personal identifiable information is collected from the Users who view and browse the Platform. The below information will be collected from the Platform visitors:</p>
            <ul className='flex flex-col gap-5 list-decimal ml-5'>
                <li>Internet Protocol address, browser type, browser language, referring URL, files accessed, errors generated, time zone,and other visitor details collected in the log files;</li>
                <li>Attributes such as the operating system, hardware version, device settings, file and software names and types, battery and signal strength, application crash details and device identifiers;</li>
                <li>Behavioural data such as frequently visited pages, time spent on pages, pages not accessed frequently.</li>
            </ul>
           
        </div>
        <div className=' flex flex-col gap-5'>
  <h5 className='font-Jakarta text-3xl font-bold'>Registered Users</h5>
  <p className='font-DM-Sans text-[15px]'>
    In the case of a User who registers to avail of the services through the Platform, the Company collects personally identifiable information, in addition to the information mentioned in point 1 above, from the User order to effectively provide the services. The personally identifiable information collected from Users is as below:
  </p>
  <ul className='flex flex-col gap-5 list-decimal ml-5'>
    <li>Your Personal Information such as, but is not limited to, Your name, age, location, date of birth, occupation, gender, permanent address, and temporary address;</li>
    <li>Your mobile number for registration to the Platform via OTP;</li>
    <li>SMS access to extract the OTP sent for registration;</li>
    <li>In case you register through your existing e-mail accounts or any existing social media account as permitted in the Platform, we will store the information about you – Your First Name, Last Name and e-mail id.;</li>
    <li>Your financial information at the time of purchase of product/services and/or online payment done through the Platform. Please be informed we do not store such information;</li>
    <li>Your charging session history and your charger usage;</li>
    <li>Your vehicle details uploaded in the Platform;</li>
    <li>User location, charging station location searches, including specific geographic locations, such as through GPS, Bluetooth or Wi-Fi signals.</li>
  </ul>
</div>
<div className=' flex flex-col gap-5'>
  <h5 className='font-Jakarta text-3xl font-bold'>Charging Station Operators</h5>
  <p className='font-DM-Sans text-[15px]'>
    In case the registered User has an installed a charging station through the Company, the following information in addition to the information set out in point 1 and 2 above, the below mentioned information shall be collected from them:
  </p>
  <ul className='flex flex-col gap-5 list-decimal ml-5'>
    <li>Charger Usage and Charger details;</li>
    <li>Charging Session History;</li>
    <li>Charger location details;</li>
    <li>Charger Billing details;</li>
    <li>Charger Energy Usage.</li>
  </ul>
</div>
<div className='flex flex-col gap-5'>
  <h5 className='font-Jakarta text-3xl font-bold'>How We Use the Information Collected</h5>
  <ul className='flex flex-col gap-5 list-decimal ml-5'>
    <li>Providing our services to Users;</li>
    <li>Process your requests and orders for services;</li>
    <li>Communicate with you regarding any clarifications raised by You with respect to services;</li>
    <li>Auditing, research and analysis in order to maintain, protect and improve our services;</li>
    <li>Improvement of our products and services;</li>
    <li>Sending periodic promotional emails about new services, enhancements, special deals, or other information that may be of interest to you for marketing purposes. For the same reason, we may contact you via email or phone;</li>
    <li>To put information security practices in place;</li>
    <li>To investigate, prevent or respond to criminal actions including suspected fraud.</li>
  </ul>
</div>

        <div className=' flex flex-col gap-5'>
            <h5 className='font-Jakarta text-3xl font-bold'>Sharing of Information</h5>
            <p className='font-DM-Sans text-[15px]'>
            The personally identifiable information shared by the Users is not shared/disclosed to any third party, except as stated herein, and in any case, it is solely used for the purposes of offering the services through the Platform. Access by Company, charging station hosts, payment gateway providers, or their respective employees and consultants to any personal information you provide is limited to only information necessary to provide the business service offered and to allow employees or consultants to perform their duties. We may aggregate information and data about you and your use of the Platform and provide such aggregated information and data to customers, partners, and advertisers for marketing or promotional purposes. We would like to you specifically inform that We will share energy usage details, charging session details and invoice details for analytics with authorised third parties for studying and conducting an analysis to better the functionality of the services. In these circumstances, we do not disclose information that could be used to personally identify you.:
            </p>
        </div>
        <div className=' flex flex-col gap-5'>
            <h5 className='font-Jakarta text-3xl font-bold'>Third-Party Sites and services</h5>
            <p className='font-DM-Sans text-[15px]'>
            This Privacy Policy does not address, and We are not responsible when You use third-party apps, websites or other services that use, or are integrated with, our services. In addition, when You download or use such third-party services, they can access Your username or user ID, your age and country/language, as well as any information that You share with them. Information collected by these apps, websites or integrated services is subject to their own terms and policies. The availability of, or inclusion of a link to, any such site or property on the Platform does not imply endorsement of it by MERAS Plugins. This shall apply for all advertisements placed on the Platform as well.
            </p>
        </div>
        <div className='flex flex-col gap-5'>
  <h5 className='font-Jakarta text-3xl font-bold'>Security</h5>
  <p className='font-DM-Sans text-[15px]'>
  When the User places orders or accesses account information, the Company offers the use of a secure server. The secure server software (“SSL”) encrypts all information put in before it is sent over to the Company. Furthermore, all User data collected is protected against unauthorized access. To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, the Company employs reasonable security practices and procedures and current Internet security methods and technologies in compliance with Information Technology Act, 2000 and rules made there under.:
  </p>

</div>

        <div className=' flex flex-col gap-5'>
            <h5 className='font-Jakarta text-3xl font-bold'>Deletion of Account</h5>
            <p className='font-DM-Sans text-[15px]'>
            We store data for as long as it is necessary to provide services to You. Information associated with Your account will be kept until Your account is deleted. You can delete Your account any time.
            </p>
        </div>
     
<div className=' flex flex-col gap-5'>
  <h5 className='font-Jakarta text-3xl font-bold'>Changes to Privacy Policy</h5>
  <p className='font-DM-Sans text-[15px]'>
  t is our policy to post any changes we make to our Privacy Policy on this page (with a notice that the Privacy Policy has been updated on the Platform). The date on which the Privacy Policy was last revised is identified at the top of the page. You are responsible for ensuring We have an up-to-date active and deliverable email address for You, and for periodically visiting our Platform and this Privacy Policy to check for any changes. Your continued use of the services after such modifications will constitute Your agreement to the updated terms of the modified Privacy Policy.
  </p>
</div>
<div className=' flex flex-col gap-5'>
  <h5 className='font-Jakarta text-3xl font-bold'>DISCLAIMER</h5>
  <p className='font-DM-Sans text-[15px]'>THE COMPANY CANNOT AND DOES NOT ENSURE THAT ANY OR ALL OF THE USER’S PRIVATE COMMUNICATIONS AND OTHER PERSONAL INFORMATION WILL NEVER BE DISCLOSED IN WAYS NOT OTHERWISE DESCRIBED IN THIS PRIVACY POLICY. BY WAY OF EXAMPLE (AND NOT BY LIMITATION), THIRD PARTIES MAY UNLAWFULLY INTERCEPT OR ACCESS TRANSMISSIONS OR PRIVATE COMMUNICATIONS, OR USER’S MAY ABUSE OR MISUSE ANY PERSONAL INFORMATION THAT THEY COLLECT FROM THE PLATFORM, WHETHER LAWFULLY OR NOT. THEREFORE, ALTHOUGH THE COMPANY USES INDUSTRY STANDARD PRACTICES (AND FOLLOWS ALL RELEVANT LEGISLATION, RULES, AND REGULATIONS) TO PROTECT THE USER’S PRIVACY, THE COMPANY DOES NOT PROMISE (AND THE USER SHOULD NOT EXPECT) THAT ANY PERSONAL INFORMATION OR PRIVATE COMMUNICATIONS WILL ALWAYS REMAIN PRIVATE.
  </p>
</div>
<div className=' flex flex-col gap-5'>
  <h5 className='font-Jakarta text-3xl font-bold'>Contact Us</h5>
  <p className='font-DM-Sans text-[15px]'>
    Any communication or notice required other this Terms of Use shall be sent to MERAS Plugins by email to support@india1charger.com.
  </p>
</div>


    </section>
    </>
  )
}

export default PrivacyPolicy